import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { BsArrowRight } from 'react-icons/bs'
import { useProject } from '../hooks/ProjectsContext'
import { Body, FavoritesArea } from '../styles/pages/landing'

import Navbar from '../components/navbar'
import Footer from '../components/footer'
import NavabarContent from '../components/navbar/Content'
import Modal from '../components/Modal'

const Landing: React.FC = () => {
  const { destaque } = useProject()
  const [modal, setModal] = useState(false)

  return (
    <>
      {modal && (
        <Modal id="navbar" onClose={() => setModal(false)}>
          <NavabarContent onClose={() => setModal(false)} />
        </Modal>
      )}
      <Body>
        <Navbar ShowModal={() => setModal(true)} />
        <h1 className="intro"> Destaques </h1>
        <FavoritesArea>
          {destaque.map((projeto) => {
            const find = projeto.imgs.find((img) => img.type === '1')

            const scnd = projeto.imgs.filter((img) => img.type === '2')

            return (
              <div className="fav" key={projeto.id} id="fav">
                <img
                  src={find ? find.url : ''}
                  className="img"
                  alt={projeto.title}
                />
                <div className="layer">
                  <div className="text">
                    <h1>{projeto.title}</h1>
                    <p>{projeto.description}</p>
                    <Link to={`/project/${projeto.id}`}>
                      <button className="btn" type="button">
                        Veja Mais
                        <div className="arrow">
                          <BsArrowRight />
                        </div>
                      </button>
                    </Link>
                  </div>
                  <div className="imagesArea">
                    {scnd.map((secondary) => {
                      return (
                        <div key={secondary.id} className="img-scnd">
                          <img
                            src={secondary.url}
                            className="img1"
                            alt={secondary.originalName}
                          />
                        </div>
                      )
                    })}
                  </div>
                </div>
              </div>
            )
          })}
        </FavoritesArea>
        <Footer />
      </Body>
    </>
  )
}

export default Landing
