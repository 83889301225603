/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React from 'react'
import { Link } from 'react-router-dom'

import { FiMenu, FiInstagram } from 'react-icons/fi'
import { AiOutlineLinkedin, AiOutlineWhatsApp } from 'react-icons/ai'
import { NavbarHeader } from './styles'

import Logo from '../Logo'

interface About {
  ShowModal(): void
}

const NavbarComp: React.FC<About> = ({ ShowModal }) => {
  return (
    <NavbarHeader>
      <div className="navbar">
        <ul>
          <Link to="/landing">
            <div className="navbarLogo">
              <Logo />
            </div>
          </Link>
          <li
            className="menu"
            style={{ cursor: 'pointer ' }}
            onClick={() => ShowModal()}
          >
            <FiMenu size={30} color="white" />
          </li>

          <li>
            <Link to="/landing" className="navbar-button hover">
              Inicio
            </Link>
          </li>

          <li>
            <Link to="/portfolio" className="navbar-button hover">
              Portfólio
            </Link>
          </li>

          <li>
            <Link to="/about" className="navbar-button hover">
              Sobre
            </Link>
          </li>
          <li className="iconAreaNavbar">
            <a
              href="https://api.whatsapp.com/send?phone=55329166-9191"
              target="_blank"
              rel="noreferrer"
            >
              <AiOutlineWhatsApp size={22} />
            </a>

            <a
              href="https://instagram.com/arq.rayane?igshid=14vr7kh5ufm1m"
              target="_blank"
              rel="noreferrer"
            >
              <FiInstagram size={22} />
            </a>

            <a
              href="https://www.linkedin.com/in/rayane-lima-cez%C3%A1rio-4bbab910b/"
              target="_blank"
              rel="noreferrer"
            >
              <AiOutlineLinkedin size={22} />
            </a>
          </li>
          <li>
            <a href="#footer" className="navbar-button hover">
              Contato
            </a>
          </li>
        </ul>
      </div>
    </NavbarHeader>
  )
}

export default NavbarComp
