/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable react/jsx-one-expression-per-line */
import React, { useState } from 'react'
import { Redirect, useRouteMatch } from 'react-router-dom'
import Masonry from 'react-masonry-css'
import 'react-responsive-modal/styles.css'
import { Modal as ModalReact } from 'react-responsive-modal'
import { useProject } from '../hooks/ProjectsContext'
import {
  Body,
  DescArea,
  ModalImg,
  NoImgWarning,
  Area,
} from '../styles/pages/project'

import Navbar from '../components/navbar'

import Footer from '../components/footer'
import Modal from '../components/Modal'
import ContentNavbarModal from '../components/navbar/Content'

interface RepositoryParams {
  id: string
}

const Project: React.FC = () => {
  const { projects } = useProject()
  const [open, setOpen] = useState(false)
  const [sendId, setSendId] = useState('')

  const onOpenModal = () => setOpen(true)
  const onCloseModal = () => setOpen(false)

  const [modal, setModal] = useState(false)
  const { params } = useRouteMatch<RepositoryParams>()
  const projeto = projects.find((projet) => projet.id === params.id)
  const responsiveBreakpoints = {
    default: 3,
    768: 2,
    480: 1,
  }

  if (projeto) {
    return (
      <>
        {modal && (
          <Modal id="navbar" onClose={() => setModal(false)}>
            <ContentNavbarModal onClose={() => setModal(false)} />
          </Modal>
        )}
        <Body>
          <Navbar ShowModal={() => setModal(true)} />
          <DescArea>
            <div className="date">
              {projeto.localization},{projeto.data}
            </div>
            <div className="name">
              <h1 className="h1Name">{projeto.title}</h1>
            </div>

            <div className="description">{projeto.description}</div>
            <Area
              style={{
                justifyContent: `${
                  projeto.imgs.length > 0 ? 'unset' : 'center'
                }`,
              }}
            >
              {projeto.imgs.length > 0 ? (
                <>
                  <h1> Galeria de Fotos </h1>

                  <Masonry
                    breakpointCols={responsiveBreakpoints}
                    className="my-masonry-grid"
                    columnClassName="my-masonry-grid_column"
                  >
                    {projeto.imgs.map((img) => (
                      <img
                        onClick={() => {
                          onOpenModal()
                          setSendId(img.url)
                        }}
                        key={img.id}
                        src={img.url}
                        alt={img.originalName}
                      />
                    ))}
                  </Masonry>

                  <ModalReact
                    open={open}
                    onClose={onCloseModal}
                    styles={{
                      modalContainer: {
                        maxHeight: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      },
                      modal: {
                        background: 'none',
                        display: 'flex',
                        justifyContent: 'center',
                        boxShadow: 'none',
                        maxWidth: '75%',
                      },
                      overlay: {
                        background: '#cecece',
                        opacity: '0.6',
                        width: '100%',
                      },
                    }}
                    center
                  >
                    <ModalImg src={sendId} alt="Imagem" />
                  </ModalReact>
                </>
              ) : (
                <NoImgWarning>
                  <h1> Nenhuma imagem encontrada! </h1>
                  <p>
                    {' '}
                    Fique de olho para não perder atualizações
                    <p>&#128521;</p>
                  </p>
                </NoImgWarning>
              )}
            </Area>
          </DescArea>
          <Footer />
        </Body>
      </>
    )
  }
  return <Redirect to="/portfolio" />
}

export default Project
