/* eslint-disable react/jsx-one-expression-per-line */
import React, { useState } from 'react'
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io'

import Navbar from '../components/navbar'
import NavabarContent from '../components/navbar/Content'
import Footer from '../components/footer'

import imgRayane from '../images/ray.jpg'

import Sketchup from '../images/svgs/sketchup.svg'
import Revit from '../images/svgs/revit.svg'
import Indesign from '../images/svgs/indesign.svg'
import Lumion from '../images/svgs/lumion.svg'
import archicad from '../images/svgs/archicad.svg'
import illustrator from '../images/svgs/illustrator.svg'
import vray from '../images/svgs/v-ray.svg'
import photoshop from '../images/svgs/photoshop.svg'
import autocad from '../images/svgs/autocad.svg'
import { Body, Slide2, Slide3 } from '../styles/pages/about'
import Modal from '../components/Modal'

const About: React.FC = () => {
  const [modal, setModal] = useState(false)
  return (
    <>
      {modal && (
        <Modal id="navbar" onClose={() => setModal(false)}>
          <NavabarContent onClose={() => setModal(false)} />
        </Modal>
      )}
      <Body className="aboutBody">
        <Navbar ShowModal={() => setModal(true)} />

        <Slide2 id="section2">
          <div className="layer">
            <div className="sld2Image">
              <img alt="rayane" className="descImg" src={imgRayane} />
            </div>

            <div className="description">
              <div className="descLabel">RAYANE LIMA CEZÁRIO</div>
              <p>
                <strong style={{ fontWeight: 400 }}>
                  Arquiteta e Urbanista{' '}
                </strong>
                formada pela Universidade Federal de São João Del Rei- MG (UFSJ)
                e{' '}
                <strong style={{ fontWeight: 400 }}>
                  Pós graduada em Arquitetura Hospitalar pelo Albert Einstein.
                </strong>
              </p>
              <br />
              <p>
                Estagiou na
                <strong style={{ fontWeight: 400 }}>
                  {' '}
                  Secretaria de Cultura de São João Del-Rei{' '}
                </strong>
                , no setor de Patrimônio e Projetos Urbanos, atuando na
                aprovação de projetoas junto aos conselhos , IPHAN e caixa
                econômica. Também estagiou no escritório
                <strong style={{ fontWeight: 400 }}>
                  {' '}
                  Fagundes Arquitetura{' '}
                </strong>
                desenvolvimento de projetos comerciais e principalmente
                restauração e recuperação de algumas das edificações afetadas
                pelo Rompimento da barragem de fundão da Vale em Mariana-MG e
                cidades vizinhas , através da
                <strong style={{ fontWeight: 400 }}> Fundação Renova</strong>.
              </p>
              <br />
              <p>
                Atualmente, realiza projetos de arquitetura e interiores
                autonomamente e cursa
                <strong style={{ fontWeight: 400 }}>
                  {' '}
                  Mestrado em Artes, Urbanidades e Sustentabilidade{' '}
                </strong>
                pela UFSJ.
              </p>
              <br />

              <p>
                Aptidão por projetos arquitetônicos e urbanísticos nas mais
                variadas escalas, buscando aliar funcionalidade e beleza para
                trazer bem estar em espaços que se adequem as necessecidades de
                cada cliente.
              </p>
            </div>

            <div className="btn">
              <button type="button" className="more">
                <a href="#section3" className="btn-link">
                  <div className="arrow">
                    <IoIosArrowDown />
                  </div>
                </a>
              </button>
            </div>
          </div>
        </Slide2>

        <Slide3 id="section3">
          <div className="layer">
            <div className="specsLabel">Habilidades</div>
            <div className="specs">
              <div className="iconArea">
                <div>
                  <img src={Sketchup} className="sketchup" alt="Sketchup" />
                  <div className="iconLabel">Sketchup</div>
                </div>

                <div>
                  <img src={vray} className="sketchup" alt="vray" />
                  <div className="iconLabel">V-ray</div>
                </div>

                <div>
                  <img src={Lumion} className="lumion" alt="Lumion" />
                  <div className="iconLabel">Lumion</div>
                </div>
                <div>
                  <img
                    src={illustrator}
                    className="sketchup"
                    alt="illustrator"
                  />
                  <div className="iconLabel">Illustrator</div>
                </div>
                <div>
                  <img src={photoshop} className="sketchup" alt="photoshop" />
                  <div className="iconLabel">Photoshop</div>
                </div>
                <div>
                  <img src={autocad} className="sketchup" alt="autocad" />
                  <div className="iconLabel">Autocad</div>
                </div>

                <div>
                  <img src={Revit} className="revit" alt="revit" />
                  <div className="iconLabel">Revit</div>
                </div>

                <div>
                  <img src={Indesign} className="indesign" alt="indesign" />
                  <div className="iconLabel">InDesign</div>
                </div>
                <div>
                  <img src={archicad} className="sketchup" alt="sketchup" />
                  <div className="iconLabel">Archicad</div>
                </div>
              </div>

              <div className="btn">
                <button type="button" className="more">
                  <a href="#section2" className="btn-link">
                    <div className="arrow">
                      <IoIosArrowUp />
                    </div>
                  </a>
                </button>
              </div>
            </div>
          </div>
        </Slide3>

        <Footer />
      </Body>
    </>
  )
}

export default About
