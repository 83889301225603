import styled from 'styled-components'

export const Body = styled.div`
  height: 100vh;
  width: 100vw;
  margin: 0;
  box-sizing: border-box;
  background: rgba(70, 70, 70, 0.1);
  overflow-y: scroll;
  position: absolute;

  @keyframes goDown-mb {
    0% {
      transform: translateY(-10vh);
      opacity: 0;
    }
    10% {
      transform: translateY(-10vh);
      opacity: 0;
    }
    20%,
    100% {
      transform: translateY(0vh);
      opacity: 1;
    }
  }

  @media screen and (max-width: 480px) {
    .intro {
      width: 100vw;
      text-align: center;
      font-size: 8vw;
      font-family: 'Lato', serif;
      font-weight: 300;
      animation: goDown-mb 15s normal;
    }
  }

  @media screen and (min-width: 481px) and (max-width: 768px) {
    .intro {
      width: 100vw;
      text-align: center;
      font-size: 6vh;
      font-family: 'Lato', serif;
      font-weight: 300;
      animation: goDown-mb 14s normal;
    }
  }

  @media screen and (min-width: 769px) {
    .intro {
      display: none;
    }
  }

  ::-webkit-scrollbar {
    display: none;
  }
`
export const FavoritesArea = styled.div`
  // Desktop
  @media screen and (min-width: 1201px) {
    height: 100vh;
    .btn::before {
      content: '';
      position: absolute;
      width: 100%;
      height: 0.32vh;
      bottom: 0;
      left: 0;
      background-color: #000;
      visibility: hidden;
      transform: scaleX(0);
      transition: all 0.3s ease-in-out 0s;
    }

    .btn:hover::before {
      visibility: visible;
      transform: scaleX(1);
    }

    .btn {
      background: none;
      border: none;
      font-size: 1.5vw;
      position: relative;
      margin-top: 4vh;
      left: 12vw;

      transition: 0.4s;

      :hover {
      }

      .arrow {
        vertical-align: -20%;
        margin-left: 0.3vw;
        display: inline-block;
      }
    }

    .layer,
    .img,
    .fav {
      transition: 0.6s;
      height: 58vh;
      width: 20vw;
      position: absolute;
    }

    .layer {
      background: rgba(245, 245, 245, 0);
      overflow: hidden;
    }

    .img {
      object-fit: cover;
      object-position: 50%;
    }

    .text {
      background: rgba(245, 245, 245, 0.4);
      border-radius: 1.4vh;
      position: absolute;
      width: 34vw;
      visibility: hidden;
      opacity: 0;
      transition: visibility 0.2s, opacity 0.4s;
      font-family: 'Lato', serif;
      text-align: justify;
      left: 10vw;
      top: 12vh;
      font-size: 1.4vw;

      padding: 2vh;

      p {
        margin: 3vh;
        margin-bottom: 0;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 6; /* number of lines to show */
        -webkit-box-orient: vertical;
      }

      h1 {
        font-weight: 400;
        text-align: center;
      }
    }

    .fav {
      top: 12vh;
      left: 5vw;
      box-shadow: 0vh 0vh 10px 1px rgba(0, 0, 0, 0.3);
      z-index: 1;
      animation: Down 15s normal;

      :hover {
        height: 85vh;
        width: 90vw;
        z-index: 2;
        top: 11vh;

        .text {
          visibility: visible;
          opacity: 1;
        }

        .imagesArea {
          visibility: visible;
          opacity: 1;
        }

        .img {
          height: 85vh;
          width: 90vw;
        }

        .layer {
          background: rgba(245, 245, 245, 0.2);
          height: 85vh;
          width: 90vw;
        }
      }

      + .fav {
        left: 28vw;
        top: 35vh;
        z-index: 1;
        animation: Up 20s normal;

        :hover {
          left: 5vw;
          top: 11vh;
          z-index: 2;
        }

        + .fav {
          left: 51vw;
          top: 12vh;
          z-index: 1;
          animation: Down 15s normal;

          :hover {
            left: 5vw;
            top: 11vh;
            z-index: 2;
          }

          + .fav {
            left: 74vw;
            top: 35vh;
            z-index: 1;
            animation: Up 20s normal;

            :hover {
              left: 5vw;
              top: 11vh;
              z-index: 2;
            }
          }
        }
      }
    }

    .imagesArea {
      height: 20vh;
      width: 20vw;
      left: 60vw;
      visibility: hidden;
      position: relative;
      top: 15vh;
      right: 9vw;
      transition: visibility 0.4s, opacity 0.6s;
      opacity: 0;

      .img1 {
        height: 28vh;
        width: 22vw;
        object-fit: cover;
        object-position: 50% 50%;
        border-radius: 1.6vh;
      }

      .img-scnd {
        right: 10vw;
        position: relative;
        margin-bottom: 2vh;
        height: 28vh;
        width: 22vw;
        box-shadow: 0vh 0vh 10px 1px rgba(0, 0, 0, 0.3);
        border-radius: 1.6vh;

        + .img-scnd {
          right: 0;
        }
      }
    }

    @keyframes Down {
      0% {
        transform: translateY(-100vh);
        opacity: 0;
      }
      10% {
        transform: translateY(-100vh);
        opacity: 0;
      }
      20%,
      100% {
        transform: translateY(0vh);
        opacity: 1;
      }
    }

    @keyframes Up {
      0% {
        transform: translateY(100vh);
        opacity: 0;
      }
      10% {
        transform: translateY(100vh);
        opacity: 0;
      }
      20%,
      100% {
        transform: translateY(0vh);
        opacity: 1;
      }
    }
  }

  // Laptops
  @media screen and (min-width: 769px) and (max-width: 1200px) {
    height: calc(100vh - 70px);
    .btn::before {
      content: '';
      position: absolute;
      width: 100%;
      height: 0.32vh;
      bottom: 0;
      left: 0;
      background-color: #000;
      visibility: hidden;
      transform: scaleX(0);
      transition: all 0.3s ease-in-out 0s;
    }

    .btn:hover::before {
      visibility: visible;
      transform: scaleX(1);
    }

    .btn {
      background: none;
      border: none;
      font-size: 1.5vw;
      position: relative;
      margin-top: 4vh;
      left: 12vw;

      transition: 0.4s;

      :hover {
      }

      .arrow {
        vertical-align: -20%;
        margin-left: 0.3vw;
        display: inline-block;
      }
    }

    .layer,
    .img,
    .fav {
      transition: 0.6s;
      height: 25vw;
      width: 20vw;
      position: absolute;
    }

    .layer {
      background: rgba(245, 245, 245, 0);
      overflow: hidden;
    }

    .img {
      object-fit: cover;
      object-position: 50%;
    }

    .text {
      background: rgba(245, 245, 245, 0.4);
      border-radius: 1.4vh;
      position: absolute;
      width: 34vw;
      visibility: hidden;
      opacity: 0;
      transition: visibility 0.2s, opacity 0.4s;
      font-family: 'Lato', serif;
      text-align: justify;
      left: 10vw;
      top: 20vh;
      font-size: 1.6vw;

      padding: 2vh;

      p {
        margin: 3vh;
        margin-bottom: 0;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 6; /* number of lines to show */
        -webkit-box-orient: vertical;
      }

      h1 {
        font-weight: 400;
        text-align: center;
      }
    }

    .fav {
      top: 10vw;
      left: 5vw;
      box-shadow: 0vh 0vh 10px 1px rgba(0, 0, 0, 0.3);
      z-index: 1;
      animation: Down 15s normal;

      :hover {
        height: 80vh;
        width: 93vw;
        z-index: 2;
        top: 11vh;

        .text {
          visibility: visible;
          opacity: 1;
        }

        .imagesArea {
          visibility: visible;
          opacity: 1;
        }

        .img {
          height: 80vh;
          width: 93vw;
        }

        .layer {
          background: rgba(245, 245, 245, 0.2);
          height: 80vh;
          width: 93vw;
        }
      }

      + .fav {
        left: 29vw;
        top: 22vw;
        z-index: 1;
        animation: Up 20s normal;

        :hover {
          left: 5vw;
          top: 11vh;
          z-index: 2;
        }

        + .fav {
          left: 53vw;
          top: 10vw;
          z-index: 1;
          animation: Down 15s normal;

          :hover {
            left: 5vw;
            top: 11vh;
            z-index: 2;
          }

          + .fav {
            left: 77vw;
            top: 22vw;
            z-index: 1;
            animation: Up 20s normal;

            :hover {
              left: 5vw;
              top: 11vh;
              z-index: 2;
            }
          }
        }
      }
    }

    .imagesArea {
      height: 20vh;
      width: 20vw;
      left: 60vw;
      visibility: hidden;
      position: relative;
      top: 15vh;
      right: 9vw;
      transition: visibility 0.4s, opacity 0.6s;
      opacity: 0;

      .img1 {
        height: 28vh;
        width: 22vw;
        object-fit: cover;
        object-position: 50% 50%;
        border-radius: 1.6vh;
      }

      .img-scnd {
        right: 10vw;
        position: relative;
        margin-bottom: 2vh;
        height: 28vh;
        width: 22vw;
        box-shadow: 0vh 0vh 10px 1px rgba(0, 0, 0, 0.3);
        border-radius: 1.6vh;

        + .img-scnd {
          right: 0;
        }
      }
    }

    @keyframes Down {
      0% {
        transform: translateY(-100vh);
        opacity: 0;
      }
      10% {
        transform: translateY(-100vh);
        opacity: 0;
      }
      20%,
      100% {
        transform: translateY(0vh);
        opacity: 1;
      }
    }

    @keyframes Up {
      0% {
        transform: translateY(100vh);
        opacity: 0;
      }
      10% {
        transform: translateY(100vh);
        opacity: 0;
      }
      20%,
      100% {
        transform: translateY(0vh);
        opacity: 1;
      }
    }
  }

  // Tablets
  @media screen and (min-width: 481px) and (max-width: 768px) {
    min-height: 100vh;

    .btn {
      background: none;
      border: 1px solid black;
      border-radius: 1.6vw;
      padding: 1vw;
      font-size: 2.4vw;
      position: relative;
      text-align: center;
      width: 22vw;
      margin: auto;
      text-shadow: 0.14vh 0.14vh rgba(44, 44, 44, 0.6);
      transition: 0.4s;

      .arrow {
        vertical-align: -20%;
        margin-left: 0.3vw;
        display: inline-block;
      }

      :active {
        background: rgba(140, 140, 140, 0.5);
      }
    }

    .layer,
    .img,
    .fav {
      transition: 0.6s;
      height: 50vw;
      width: 90vw;
      position: relative;
      display: block;
      margin: auto;
    }

    .layer {
      background: rgba(245, 245, 245, 0);
      overflow: hidden;
    }

    .img {
      object-fit: cover;
      object-position: 50%;
      position: absolute;
    }

    .text {
      background: rgba(245, 245, 245, 0.4);
      position: absolute;
      width: 100%;
      bottom: 6vw;
      margin: auto;
      visibility: hidden;
      opacity: 0;
      transition: visibility 0.2s, opacity 0.4s;
      font-family: 'Lato', serif;
      text-align: center;

      padding: 2vw;

      p {
        margin: 2vw;
        overflow: hidden;
        font-size: 1.2rem;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 3; /* number of lines to show */
        -webkit-box-orient: vertical;
        margin-left: 15%;
        margin-right: 15%;
      }

      h1 {
        font-weight: 400;
        text-align: center;
        font-size: 1.8rem;
        font-family: 'Lato', serif;
      }
    }

    .fav {
      box-shadow: 0vh 0vh 10px 1px rgba(0, 0, 0, 0.3);
      margin-bottom: 2vw;
      margin-top: 4vw;
      animation: goRight-mb 14s normal;

      :hover {
        height: 70vw;
        width: 90vw;

        .text {
          visibility: visible;
          opacity: 1;
        }

        .imagesArea {
          visibility: visible;
          opacity: 1;
        }

        .img {
          height: 70vw;
          width: 90vw;
        }

        .layer {
          height: 70vw;
          width: 90vw;
        }
      }

      + .fav {
        animation: goLeft-mb 14s normal;

        + .fav {
          animation: goRight-mb 14s normal;

          + .fav {
            animation: goLeft-mb 14s normal;
          }
        }
      }
    }

    .imagesArea {
      height: 30vh;
      width: 62vw;
      margin: auto;
      visibility: hidden;
      position: relative;
      transition: visibility 0.4s, opacity 0.6s;
      opacity: 0;
      display: flex;
      flex-direction: row;
      align-items: center;

      .img-scnd {
        position: relative;
        box-shadow: 0vh 0vh 10px 1px rgba(0, 0, 0, 0.3);
        border-radius: 1.6vh;
        height: 20vw;
        width: 30vw;
        margin: 1vh;
      }

      .img1 {
        height: 20vw;
        width: 30vw;
        object-fit: cover;
        object-position: 50% 50%;
        border-radius: 1.6vh;
        position: relative;
      }
    }

    @keyframes goRight-mb {
      0% {
        transform: translateX(-100vh);
        opacity: 0;
      }
      10% {
        transform: translateX(-100vh);
        opacity: 0;
      }
      20%,
      100% {
        transform: translateX(0vh);
        opacity: 1;
      }
    }

    @keyframes goLeft-mb {
      0% {
        transform: translateX(100vh);
        opacity: 0;
      }
      10% {
        transform: translateX(100vh);
        opacity: 0;
      }
      20%,
      100% {
        transform: translateX(0vh);
        opacity: 1;
      }
    }
  }

  // Cellphones
  @media screen and (max-width: 480px) {
    min-height: 150vh;

    .btn {
      background: none;
      border: 1px solid black;
      border-radius: 1.6vw;
      padding: 1vw;
      font-size: 3vw;
      position: relative;
      text-align: center;
      width: 27vw;
      margin: auto;

      transition: 0.4s;

      .arrow {
        vertical-align: -20%;
        margin-left: 0.3vw;
        display: inline-block;
      }

      :active {
        background: rgba(140, 140, 140, 0.5);
      }
    }

    .layer,
    .img,
    .fav {
      transition: 0.6s;
      height: 70vw;
      width: 70vw;
      position: relative;
      display: block;
      margin: auto;
    }

    .layer {
      background: rgba(245, 245, 245, 0);
      overflow: hidden;
    }

    .img {
      object-fit: cover;
      object-position: 50%;
      position: absolute;
    }

    .text {
      background: rgba(245, 245, 245, 0.4);
      position: absolute;
      width: 100%;
      bottom: 10vw;
      margin: auto;
      visibility: hidden;
      opacity: 0;
      transition: visibility 0.2s, opacity 0.4s;
      font-family: 'Lato', serif;
      font-weight: 300;
      text-align: center;
      padding: 2vw;

      p {
        margin: 2vw;
        margin-bottom: 2vw;
        overflow: hidden;
        font-size: 1rem;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 3; /* number of lines to show */
        -webkit-box-orient: vertical;
      }

      h1 {
        font-weight: 400;
        text-align: center;
        font-size: 1.3rem;
      }
    }

    .fav {
      box-shadow: 0vh 0vh 10px 1px rgba(0, 0, 0, 0.3);
      margin-bottom: 2vw;
      margin-top: 4vw;
      animation: goRight-mb 14s normal;

      :hover {
        height: 120vw;
        width: 80vw;

        .text {
          visibility: visible;
          opacity: 1;
        }

        .imagesArea {
          visibility: visible;
          opacity: 1;
        }

        .img {
          height: 120vw;
          width: 80vw;
        }

        .layer {
          height: 120vw;
          width: 80vw;
        }
      }

      + .fav {
        animation: goLeft-mb 14s normal;

        + .fav {
          animation: goRight-mb 14s normal;

          + .fav {
            animation: goRight-mb 14s normal;
          }
        }
      }
    }

    .imagesArea {
      height: 20vw;
      width: 20vw;
      margin: auto;
      visibility: hidden;
      position: relative;
      top: 5vh;
      transition: visibility 0.4s, opacity 0.6s;
      opacity: 0;
      display: flex;
      flex-direction: column;
      align-items: center;

      .img-scnd {
        position: relative;
        box-shadow: 0vh 0vh 10px 1px rgba(0, 0, 0, 0.3);
        border-radius: 1.6vh;
        height: 22vw;
        width: 45vw;
        margin-bottom: 2vw;
      }

      .img1 {
        height: 22vw;
        width: 45vw;
        object-fit: cover;
        object-position: 50% 50%;
        border-radius: 1.6vh;
        position: relative;
      }
    }

    @keyframes goRight-mb {
      0% {
        transform: translateX(-100vh);
        opacity: 0;
      }
      10% {
        transform: translateX(-100vh);
        opacity: 0;
      }
      20%,
      100% {
        transform: translateX(0vh);
        opacity: 1;
      }
    }

    @keyframes goLeft-mb {
      0% {
        transform: translateX(100vh);
        opacity: 0;
      }
      10% {
        transform: translateX(100vh);
        opacity: 0;
      }
      20%,
      100% {
        transform: translateX(0vh);
        opacity: 1;
      }
    }
  }
`
