import styled from 'styled-components'

export const Body = styled.div`
  height: 100vh;
  width: 100vw;
  box-sizing: border-box;
  margin: 0;
  position: absolute;
  overflow-x: hidden;

  @media screen and (min-width: 1201px) {
    .intro {
      margin-top: 2vw;
      width: 100vw;
      text-align: center;
      position: relative;
      animation: Opc 10s normal;
      transition: 0.6s;

      h1 {
        font-size: 30pt;
        font-family: 'Lato', serif;
        font-weight: 300;
        margin: 20px 10px 0px 10px;
      }
    }
  }

  @media screen and (min-width: 769px) and (max-width: 1200px) {
    .intro {
      width: 100vw;
      text-align: center;
      position: relative;
      animation: Opc 10s normal;
      transition: 0.6s;

      h1 {
        font-size: 30pt;
        font-family: 'Lato', serif;
        font-weight: 300;
        margin: 20px 10px 0px 10px;
      }
    }
  }

  @media screen and (min-width: 469px) and (max-width: 768px) {
    .intro {
      width: 100vw;
      text-align: center;
      position: relative;
      animation: Opc 12s normal;
      transition: 0.6s;

      h1 {
        font-size: 30pt;
        font-family: 'Lato', serif;
        font-weight: 300;
        margin: 20px 10px 0px 10px;
      }
    }
  }

  @media screen and (max-width: 480px) {
    .intro {
      width: 100vw;
      text-align: center;
      position: relative;
      animation: Opc 10s normal;
      transition: 0.6s;

      h1 {
        font-size: 30pt;
        font-family: 'Lato', serif;
        font-weight: 300;
        margin: 20px 10px 0px 10px;
        border-bottom: thin solid rgba(0, 0, 0, 0.6);
      }
    }
  }
`

export const ProjectsArea = styled.div`
  @media screen and (min-width: 1201px) {
    min-height: 150vh;
    width: 100vw;
    text-align: center;

    .link {
      text-decoration: none;
      color: white;
    }

    .img,
    .box {
      height: 40vh;
      width: 40vh;
    }

    .text,
    .img,
    .box {
      transition: 0.4s;
    }

    .text {
      position: absolute;
      bottom: 0;
      transform: translateY(10vh);
      width: 100%;
      padding: 1.6vh;
      font-family: 'Lato', serif;
      font-weight: 400;
      font-style: italic;
      text-align: center;
      visibility: hidden;
      font-size: 1.4vw;
      background: rgba(0, 0, 0, 0.5);
    }

    .img {
      position: absolute;
      object-fit: cover;
      object-position: 50% 50%;
    }
    .box {
      display: inline-block;
      position: relative;
      margin: 4vh;
      top: 0vh;
      overflow: hidden;
      box-shadow: 0vh 0vh 1vh 0.1vh;
      animation: boxShow 9s normal;
      text-align: initial;
    }

    .box:hover {
      transform: scale(1.04);

      .img {
      }

      .text {
        visibility: visible;
        opacity: 1;
        transform: scale(1) translateY(0vh);
      }
    }

    @keyframes boxShow {
      0% {
        top: 50vh;
        opacity: 0;
      }
      10% {
        top: 50vh;
        opacity: 0;
      }
      20%,
      100% {
        top: 0vh;
        opacity: 1;
      }
    }

    @keyframes Opc {
      0% {
        opacity: 0;
        left: -100vw;
      }
      10% {
        opacity: 0;
        left: -100vw;
      }
      20%,
      100% {
        opacity: 1;
        left: 0;
      }
    }
  }

  @media screen and (min-width: 1025px) and (max-width: 1200px) {
    width: 100vw;
    margin: auto;
    text-align: center;

    .link {
      text-decoration: none;
      color: white;
    }

    .img,
    .box {
      height: 35vw;
      width: 35vw;
    }

    .text,
    .img,
    .box {
      transition: 0.4s;
    }

    .text {
      position: absolute;
      bottom: 0;
      transform: translateY(10vh);
      width: 100%;
      padding: 1.6vw;
      text-align: center;
      font-family: 'Lato', serif;
      font-weight: 300;
      visibility: hidden;
      font-size: 2vw;
      background: rgba(0, 0, 0, 0.5);
    }

    .img {
      position: absolute;
      object-fit: cover;
      object-position: 50% 50%;
    }
    .box {
      display: inline-block;
      position: relative;
      margin: 4vw;
      top: 0vh;
      overflow: hidden;
      box-shadow: 0vh 0vh 1vh 0.1vh;
      animation: boxShow 9s normal;
      text-align: initial;
    }

    .box:hover {
      transform: scale(1.04);

      .img {
      }

      .text {
        visibility: visible;
        opacity: 1;
        transform: scale(1) translateY(0vh);
      }
    }

    @keyframes boxShow {
      0% {
        top: 50vh;
        opacity: 0;
      }
      10% {
        top: 50vh;
        opacity: 0;
      }
      20%,
      100% {
        top: 0vh;
        opacity: 1;
      }
    }

    @keyframes Opc {
      0% {
        opacity: 0;
        left: -100vw;
      }
      10% {
        opacity: 0;
        left: -100vw;
      }
      20%,
      100% {
        opacity: 1;
        left: 0;
      }
    }
  }

  @media screen and (min-width: 769px) and (max-width: 1024px) {
    width: 100vw;
    margin: auto;
    text-align: center;

    .link {
      text-decoration: none;
      color: white;
    }

    .img,
    .box {
      height: 35vw;
      width: 35vw;
    }

    .text,
    .img,
    .box {
      transition: 0.4s;
    }

    .text {
      position: absolute;
      bottom: 0;
      transform: translateY(10vh);
      width: 100%;
      padding: 1.6vw;
      text-align: center;
      font-family: 'Lato', serif;
      font-weight: 300;
      visibility: hidden;
      font-size: 2vw;
      background: rgba(0, 0, 0, 0.5);
    }

    .img {
      position: absolute;
      object-fit: cover;
      object-position: 50% 50%;
    }
    .box {
      display: inline-block;
      position: relative;
      margin: 4vw;
      top: 0vh;
      overflow: hidden;
      box-shadow: 0vh 0vh 1vh 0.1vh;
      animation: boxShow 9s normal;
      text-align: initial;
    }

    .box:hover {
      transform: scale(1.04);

      .img {
      }

      .text {
        visibility: visible;
        opacity: 1;
        transform: scale(1) translateY(0vh);
      }
    }

    @keyframes boxShow {
      0% {
        top: 50vh;
        opacity: 0;
      }
      10% {
        top: 50vh;
        opacity: 0;
      }
      20%,
      100% {
        top: 0vh;
        opacity: 1;
      }
    }

    @keyframes Opc {
      0% {
        opacity: 0;
        left: -100vw;
      }
      10% {
        opacity: 0;
        left: -100vw;
      }
      20%,
      100% {
        opacity: 1;
        left: 0;
      }
    }
  }

  @media screen and (min-width: 481px) and (max-width: 768px) {
    min-height: 130vh;
    display: flex;
    flex-direction: column;
    align-items: center;

    .link {
      text-decoration: none;
      color: white;
    }

    .img,
    .box {
      height: 40vw;
      width: 70vw;
    }

    .text,
    .img,
    .box {
      transition: 0.4s;
    }

    .text {
      position: absolute;
      bottom: 0;
      transform: translateY(0);
      width: 100%;
      padding: 1.6vh;
      text-align: center;
      visibility: visible;
      font-family: 'Lato', serif;
      font-weight: 300;
      font-size: 4vw;
      background: rgba(0, 0, 0, 0.5);
    }

    .img {
      position: absolute;
      object-fit: cover;
      object-position: 50% 50%;
    }
    .box {
      display: inline-block;
      position: relative;
      margin: 2vw;
      top: 0vh;
      overflow: hidden;
      box-shadow: 0vh 0vh 1vh 0.1vh;
      animation: boxShow 9s normal;
      text-align: initial;
    }

    @keyframes boxShow {
      0% {
        top: 50vh;
        opacity: 0;
      }
      10% {
        top: 50vh;
        opacity: 0;
      }
      20%,
      100% {
        top: 0vh;
        opacity: 1;
      }
    }

    @keyframes Opc {
      0% {
        opacity: 0;
        left: -100vw;
      }
      10% {
        opacity: 0;
        left: -100vw;
      }
      20%,
      100% {
        opacity: 1;
        left: 0;
      }
    }
  }

  @media screen and (max-width: 480px) {
    min-height: 130vh;
    width: 100vw;
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-bottom: 2vh;

    .link {
      text-decoration: none;
      color: white;
    }

    .img,
    .box {
      height: 70vw;
      width: 70vw;
    }

    .text,
    .img,
    .box {
      transition: 0.4s;
    }

    .text {
      position: absolute;
      bottom: 0;
      transform: translateY(0);
      width: 100%;
      padding: 1vh;
      font-family: 'Lato', serif;
      font-weight: 300;
      text-align: center;
      visibility: visible;
      font-size: 5vw;
      background: rgba(0, 0, 0, 0.5);
    }

    .img {
      position: absolute;
      object-fit: cover;
      object-position: 50% 50%;
    }
    .box {
      display: inline-block;
      position: relative;
      margin: 3vw;
      top: 0vh;
      overflow: hidden;
      box-shadow: 0vh 0vh 1vh 0.1vh;
      animation: boxShow 9s normal;
      text-align: initial;
    }

    @keyframes boxShow {
      0% {
        top: 50vh;
        opacity: 0;
      }
      10% {
        top: 50vh;
        opacity: 0;
      }
      20%,
      100% {
        top: 0vh;
        opacity: 1;
      }
    }

    @keyframes Opc {
      0% {
        opacity: 0;
        left: -100vw;
      }
      10% {
        opacity: 0;
        left: -100vw;
      }
      20%,
      100% {
        opacity: 1;
        left: 0;
      }
    }
  }
`
