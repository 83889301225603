import React from 'react'
import { Link } from 'react-router-dom'

interface props {
  onClose(): void
}
const ContentNavbarModal: React.FC<props> = ({ onClose }) => {
  return (
    <ul>
      <li>
        <Link
          to="/landing"
          onClick={() => onClose()}
          className="navbar-button hover"
        >
          Inicio
        </Link>
      </li>
      <li>
        <Link
          to="/portfolio"
          onClick={() => onClose()}
          className="navbar-button hover"
        >
          Portfólio
        </Link>
      </li>
      <li>
        <Link
          to="/about"
          onClick={() => onClose()}
          className="navbar-button hover"
        >
          Sobre
        </Link>
      </li>
      <li>
        <a
          href="#footer"
          onClick={() => onClose()}
          className="navbar-button hover"
        >
          Contato
        </a>
      </li>
    </ul>
  )
}
export default ContentNavbarModal
