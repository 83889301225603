import styled from 'styled-components'

export const Content = styled.svg`
  fill: white;
  stroke: white;
  top: 0;
  stroke-linecap: round;
  overflow: visible;
  width: 100%;

  .Bottom {
    transition: ease-in-out 1s;

    transform-origin: 250px;
  }
  .Top {
    transition: ease-in-out 1s;
    transform-origin: 350px;
  }
  .A {
    transform-origin: 320px 420px;
    transform: scale(1.5);
    transition: 0.5s 0.5s ease-out;

    opacity: 1;
  }
  .C {
    transform-origin: 240px 430px;
    transform: scale(1.5);
    transition: 0.5s 0.5s ease-out;
    opacity: 1;
  }
  .Cezario {
    path {
      stroke-width: 2;
      color: black;
    }
    transform-origin: 240px 400px;
    transform: scale(3.4);
    transition: 0.5s ease-out;
    opacity: 0;
  }
  .Arquitetura {
    path {
      stroke-width: 2;
      color: black;
    }
    transform-origin: 230px 397px;
    transform: scale(3.4);
    transition: 0.5s ease-out;
    opacity: 0;
  }

  :hover,
  :active,
  :focus {
    .A {
      opacity: 0;
      transition: ease-in-out 0.5s;
    }
    .C {
      opacity: 0;
      transition: ease-out 0.5s;
    }
    .Cezario {
      transition: ease-out 0.5s 0.5s;
      opacity: 1;
    }
    .Arquitetura {
      opacity: 1;
      transition: ease-out 0.5s 0.5s;
    }
    .Top {
      transform: scaleX(4.4);
      transform-origin: 348px;
    }

    .Bottom {
      transform: scaleX(4.4);
      transform-origin: 248px;
    }
  }
`
