/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable prettier/prettier */
import React, { useCallback, useState } from 'react'
import { useDropzone } from 'react-dropzone'
import { Container, DropContainer, UploadMessage } from './styles'
import { useAuth } from '../../hooks/AuthContext'
import Preview from '../Preview'
import api from '../../server/api'
import { useToast } from '../../hooks/ToastContext'
import { useProject } from '../../hooks/ProjectsContext'


interface Props {
  projectId: string
  type: string
  imgs?: UploaddImg
  exist: boolean
  isCreatedModal?: boolean
}
interface UploaddImg {
  id: string
  projectId: string
  key: string
  originalName: string
  type: string
  url: string
}
interface Imgs {
  originalName: string
  id: string
  type: string
  url: string
  uploaded: boolean
  error: boolean
}

const ModalImg: React.FC<Props> = ({ isCreatedModal = false, type, projectId, exist, imgs }) => {
  const [files, setFiles] = useState<Imgs>((): Imgs => {
    if (imgs) {
      return {
        originalName: imgs.originalName,
        url: imgs.url,
        id: imgs.id,
        uploaded: true,
        error: false,
        type: imgs.type,
      }
    } return {
      originalName: '',
      url: '',
      id: '',
      uploaded: false,
      error: false,
      type,
    }
  })

  const { addToast } = useToast()
  const [existimg, setExistimg] = useState(exist)
  const {RefreshData }= useProject()
  const { token } = useAuth()
  const {
    isDragActive,
    isDragReject,
    getRootProps,
    getInputProps,
  } = useDropzone({
    maxFiles: 1,
    accept: 'image/*',
    maxSize: 750000,
    onDropAccepted: async (acceptedFiles) => {


      const uploadFiles = acceptedFiles.pop()

      upload(uploadFiles)

    },
    onDropRejected: (err)=>{
      err.forEach((error)=>
      addToast({
        type: 'error',
        title: `Erro esse arquivo nao foi aceito!`,
        description: `O arquivo ${error.file.name} é muito grande ou nao faz é uma imagem!}`  ,
      })
      )}
  })
  const upload = useCallback(async (uploadFiles) => {
    try {
      const data = new FormData()
      data.append('file', uploadFiles)
      await api
        .post(`imgs/${projectId}/${type}`, data, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((response) => {
          RefreshData()
          setExistimg(true)
          addToast({
            type: 'sucess',
            title: `Sucesso ao Salvar imagem ${response.data.originalName} `,
          })
          setFiles(({ id, url, uploaded, ...rest }) => {
            return {
              id: response.data.id,
              uploaded: true,
              url: response.data.url,
              ...rest,
            }
          })

        })
    } catch (err) {
      addToast({
        type: 'error',
        title: `Erro ao tentar salvar a ${uploadFiles.name} `,
        description: err,
      })
    }
  }, [addToast,setFiles, setExistimg, projectId, token, type, RefreshData])


  const handleType = () => {
    if (type === '2') {
      return <h1>Imagens Secundaria</h1>
    } if (type === '1') {
      return <h1>Imagem Principal</h1>
    }
      return <h1>Outras Imagens</h1>

  }


  return (
    <>
      <Container isClose={existimg} iscreatedmodal={isCreatedModal}>
        <DropContainer
          {...getRootProps()}
          isDragActive={isDragActive}
          isDragReject={isDragReject}
        >
          <input {...getInputProps({ className: 'dropzone' })} />
          {!isDragActive ? (
            <UploadMessage>
              {handleType()}
              <p>Solte o Arquivo aqui</p>
              <p>(Only images will be accepted)</p>
            </UploadMessage>
          ) : isDragReject ? (
            <UploadMessage type="error">
              {handleType()}
              <p>Arquivo nao suportado</p>
              <p>(Only *.jpeg and *.png images will be accepted)</p>
            </UploadMessage>
          ) : (
            <UploadMessage type="success">
              {handleType()}
              <p>Solte o arquivo</p>
              <p>(Only *.jpeg and *.png images will be accepted)</p>
            </UploadMessage>
              )}
        </DropContainer>
      </Container>
      {existimg && <Preview iscreatedmodal={isCreatedModal} key={files.id} id={files.id} type={files.type} url={files.url} isClose={() => setExistimg(false)} />}

    </>
  )
}

export default ModalImg
