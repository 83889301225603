import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react'
import api from '../server/api'

export interface Projects {
  id: string
  title: string
  description: string
  spotligth: boolean
  localization: string
  data: string
  imgs: Array<Imgs>
}
export interface CreateProjects {
  id?: string
  title: string
  description: string
  spotligth: boolean
  localization: string
  data: string
}
export interface Imgs {
  id: string
  projectId: string
  key: string
  originalName: string
  type: string
  url: string
}
interface deletar {
  id: string
}
interface ProjectsContextData {
  projects: Array<Projects>
  destaque: Array<Projects>
  RegisterProjects(credencials: CreateProjects): Promise<Projects>
  DeletarProject(credenciials: deletar): Promise<void>
  UpdateProject(credencials: CreateProjects): Promise<void>
  RefreshData(): Promise<void>
}

const ProjectsContext = createContext<ProjectsContextData>(
  {} as ProjectsContextData
)

const ProjectsProvider: React.FC = ({ children }) => {
  // eslint-disable-next-line consistent-return
  const [alldata, setData] = useState<Projects[]>(() => {
    const dataProjcts = localStorage.getItem('@ArquiRay:projects')
    if (dataProjcts) {
      return JSON.parse(dataProjcts)
    }
  })
  const [destaque, setDestaque] = useState<Projects[]>([])
  useEffect(() => {
    api.get<Projects[]>('projects').then((response) => {
      setData(response.data)
      localStorage.setItem('@ArquiRay:projects', JSON.stringify(response.data))
      setDestaque(
        response.data.filter((projetos) => projetos.spotligth === true)
      )
    })
  }, [])

  const RegisterProjects = useCallback(
    async ({ title, description, spotligth, localization, data }) => {
      const token = localStorage.getItem('@ArquiRay:token')
      const res = await api.post(
        '/projects',
        {
          title,
          description,
          spotligth,
          localization,
          data,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      return res.data
    },
    []
  )
  const RefreshData = useCallback(async () => {
    api.get<Projects[]>('projects').then((response) => {
      setData(response.data)
      localStorage.setItem('@ArquiRay:projects', JSON.stringify(response.data))
      setDestaque(
        response.data.filter((projetos) => projetos.spotligth === true)
      )
    })
  }, [setData])
  const DeletarProject = useCallback(
    async ({ id }) => {
      const token = localStorage.getItem('@ArquiRay:token')

      await api.delete(`/projects/${id}`, {
        headers: { Authorization: `Bearer ${token}` },
      })

      setData(alldata.filter((da) => da.id !== id))
    },
    [setData, alldata]
  )
  const UpdateProject = useCallback(
    async ({ id, title, description, spotligth, localization, data }) => {
      const token = localStorage.getItem('@ArquiRay:token')
      const imgUploaded = await api.put(
        `/projects/${id}`,
        {
          title,
          description,
          spotligth,
          localization,
          data,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      setData([imgUploaded.data, ...alldata])
    },
    [setData, alldata]
  )

  return (
    <ProjectsContext.Provider
      value={{
        destaque,
        projects: alldata,
        RegisterProjects,
        DeletarProject,
        UpdateProject,
        RefreshData,
      }}
    >
      {children}
    </ProjectsContext.Provider>
  )
}

function useProject(): ProjectsContextData {
  const context = useContext(ProjectsContext)
  if (!context) {
    throw new Error('useProject must be used within an ProjectsContext')
  }
  return context
}
export { ProjectsProvider, useProject }
