import styled from 'styled-components'

export const Body = styled.div`
  height: 100vh;
  width: 100vw;
  margin: 0;
  box-sizing: border-box;

  overflow-x: hidden;
`
export const Area = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;

  > h1 {
    font-weight: 300;
    font-family: 'Lato';
    font-size: 20pt;
    margin-bottom: 1em;
  }

  @media screen and (min-width: 481px) and (max-width: 768px) {
    padding: 0;
  }

  @media screen and (max-width: 480px) {
    padding: 0;

    > h1 {
      font-size: 1.6rem;
    }
  }

  .my-masonry-grid {
    display: -webkit-box; /* Not needed if autoprefixing */
    display: -ms-flexbox; /* Not needed if autoprefixing */
    display: flex;
    width: 80%;
    margin-bottom: 70px;

    @media screen and (min-width: 481px) and (max-width: 768px) {
      width: 90%;
    }

    @media screen and (max-width: 480px) {
      width: 80%;
    }
  }

  .my-masonry-grid_column {
    padding-left: 10px; /* gutter size */
    background-clip: padding-box;

    > img {
      /* change div to reference your elements you put in <Masonry> */
      max-width: 100%;
      margin-bottom: 10px;
      transition: 0.4s;
      -moz-transition: 0.4s;
      -o-transition: 0.4s;
      -webkit-transition: 0.4s;
      z-index: 2;
    }

    > div {
      max-width: 25%;
    }

    @media screen and (min-width: 1024px) {
      > img {
        :hover {
          transform: scale(1.05);
          -webkit-transform: scale(1.05);
          -o-transform: scale(1.05);
          -moz-transform: scale(1.05);
          z-index: 10;
        }
      }
    }

    @media screen and (max-width: 480px) {
      padding-left: 0;

      > img {
        max-width: 100%;
      }
    }
  }
`

export const NoImgWarning = styled.div`
  font: bold 1.6rem Roboto, serif;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: var(--dark-red);

  > p {
    font: bold italic 1.3rem Roboto, serif;
    color: var(--dark-blue);
    opacity: 0.8;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    text-align: center;
    align-items: center;

    > p {
      font-style: normal;
      margin-left: 4px;
    }
  }

  @media screen and (max-width: 768px) {
    font-size: 0.8rem;
    padding: 0 0.5rem;
    text-align: center;
  }

  > p {
    margin: 0.5em 0;

    > p {
      margin: 0.2em 0;
    }
  }
`

export const ModalImg = styled.img`
  height: 90vh;

  @media screen and (min-width: 769px) and (max-width: 1023px) {
    height: unset;
    width: 90%;
  }

  @media screen and (min-width: 481px) and (max-width: 768px) {
    height: unset;
    width: 90%;
  }

  @media screen and (max-width: 480px) {
    height: unset;
    width: 90%;
  }
`
export const DescArea = styled.div`
  width: 100vw;
  background: rgba(200, 200, 200, 0.1);
  position: relative;
  color: rgba(0, 0, 0, 0.7);

  .name {
    .h1Name {
      font-weight: 300;
      display: inline-block;
      position: relative;
      border-bottom: 0.5px solid rgba(0, 0, 0, 0.7);
    }

    font-family: 'Lato', serif;
    width: 100vw;
    text-align: center;
    position: relative;
    top: 6vw;
    margin-bottom: 10vw;
    font-size: 1.2rem;
    animation: name 6s normal;
  }

  .description {
    font-family: 'Lato', serif;
    font-weight: 300;
    position: relative;
    font-size: 1.5rem;
    margin: auto;
    text-indent: justify;
    width: 50vw;
    text-align: center;
    margin-bottom: 50px;
    animation: desc 6s normal;
  }

  .date {
    position: absolute;
    right: 3vw;
    top: 1vw;
    font-size: 1.2rem;
    font-style: italic;
    animation: date 6s normal;
  }

  .carousel {
    position: relative;
    margin: 50px auto;
    width: 80vw;
    height: 70vh;
    animation: carousel 8s normal;
  }
  .slider {
    width: 80vw;
    height: 70vh;
    bottom: 0px;
    margin-top: 0px;
  }
  .slide {
    height: 100%;
    width: 100%;
  }
  @media (max-width: 600px) {
    .date {
      font-size: 0.8rem;
    }
    .description {
      font-size: 1.3rem;

      width: 85vw;
    }
    .carousel {
      margin: 0px;
      box-shadow: 0px 0px 0px 0px;
    }
    .slider {
      margin: 0px;
      width: 100vw;
      height: 30vh;
      bottom: 0px;
    }
  }
  @keyframes date {
    0% {
      opacity: 0;
    }
    10% {
      opacity: 0;
    }
    20%,
    100% {
      opacity: 1;
    }
  }

  @keyframes desc {
    0% {
      opacity: 0;
      transform: translateX(-10vw);
    }
    10% {
      opacity: 0;
      transform: translateX(-10vw);
    }
    20%,
    100% {
      opacity: 1;
      transform: translateX(0);
    }
  }

  @keyframes name {
    0% {
      opacity: 0;
      transform: translateX(10vw);
    }
    10% {
      opacity: 0;
      transform: translateX(10vw);
    }
    20%,
    100% {
      opacity: 1;
      transform: translateX(0vw);
    }
  }

  @keyframes carousel {
    0% {
      opacity: 0;
      transform: translateY(10vw);
    }
    10% {
      opacity: 0;
      transform: translateY(10vw);
    }
    20%,
    100% {
      opacity: 1;
      transform: translateY(0vw);
    }
  }
`
