import styled from 'styled-components'

export const Content = styled.svg`
  fill: #131313;
  stroke: #131313;
  top: 0;
  stroke-linecap: round;
  overflow: visible;
  width: 100%;
  .line {
    stroke-dasharray: 1000;
    stroke-dashoffset: 1000;

    .Bottom {
      transform: scaleX(4.3);
      transform-origin: 242px;
      animation: lineMoveBottom 6.5s forwards;
    }
    .curveOne {
      animation: lineAnimation 2s 0.6s forwards;
    }
    .middle {
      animation: lineAnimation 2s 0.7s forwards;
    }
    .curveTwo {
      animation: lineAnimation 2s 1s forwards;
    }
    .Top {
      transform: scaleX(4.3);
      transform-origin: 348px;
      animation: lineMoveTop 5.5s 1.1s forwards;
    }
  }

  .Cezario {
    path {
      stroke-width: 2;
      color: black;
    }
    transform-origin: 240px 400px;
    transform: scale(3.4);
    animation: opacityOff 4s 2s forwards;
    opacity: 0;
  }
  .Arquitetura {
    path {
      stroke-width: 2;
      color: black;
    }
    transform-origin: 230px 397px;
    transform: scale(3.4);
    animation: opacityOff 4s 2s forwards;
    opacity: 0;
  }
  .C {
    transform-origin: 240px 430px;
    transform: scale(1.5);
    animation: opacityOn 2s 5s forwards;
    opacity: 0;
  }
  .A {
    animation: opacityOn 2s 5s forwards;
    transform-origin: 320px 420px;
    transform: scale(1.5);
    opacity: 0;
  }

  @keyframes opacityOn {
    0% {
      opacity: 0;
    }

    90% {
      opacity: 1;
    }
    100% {
      opacity: 1;
    }
  }

  @keyframes opacityOff {
    0% {
      opacity: 0;
    }

    50% {
      opacity: 1;
    }
    90% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  @keyframes lineAnimation {
    from {
      stroke-dashoffset: 1000;
    }

    to {
      stroke-dashoffset: 0;
    }
  }
  @keyframes lineMoveBottom {
    0% {
      stroke-dashoffset: 1000;
      transform: scaleX(4.3);
      transform-origin: 242px;
    }
    50% {
      stroke-dashoffset: 0;
      transform: scaleX(4.3);
      transform-origin: 242px;
    }
    100% {
      stroke-dashoffset: 0;
      transform: scaleX(1);
      transform-origin: 242px;
    }
  }
  @keyframes lineMoveTop {
    0% {
      stroke-dashoffset: 1000;
      transform: scaleX(4.3);
      transform-origin: 348px;
    }
    50% {
      stroke-dashoffset: 0;
      transform: scaleX(4.3);
      transform-origin: 348px;
    }
    100% {
      stroke-dashoffset: 0;

      transform: scaleX(1);
      transform-origin: 348px;
    }
  }
`
