/* eslint-disable func-names */
import React, { useState } from 'react'
import { Redirect } from 'react-router-dom'
import { useProject } from '../hooks/ProjectsContext'

import LogoHomePage from '../components/LogoHomePage'
import { Body } from '../styles/pages/welcome'

const Welcome: React.FC = () => {
  const { destaque } = useProject()
  const [redirection, setRedirection] = useState(false)

  window.setTimeout(function () {
    setRedirection(true)
  }, 8000)

  return (
    <>
      {redirection && <Redirect to="/landing" />}
      <Body>
        <div className="logo-container">
          <div className="logo-initial">
            <LogoHomePage />
          </div>
        </div>

        <div className="container">
          {destaque.map((projeto) => {
            const find = projeto.imgs.find((img) => img.type === '1')

            return (
              <div className="Box" key={projeto.id}>
                <img src={find ? find.url : ''} id="img1" alt="img1" />
              </div>
            )
          })}
        </div>
      </Body>
    </>
  )
}

export default Welcome
