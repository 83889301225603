import styled from 'styled-components'

export const Body = styled.div`
  @media screen and (min-width: 1201px) {
    display: block;
    width: 100vw;
    height: 100vh;
    overflow: hidden;

    #img1 {
      height: 55vh;
      width: 17vw;
      object-fit: cover;
    }

    .container {
      width: 80vw;
      margin: auto;
      position: relative;
    }

    .Box {
      box-shadow: 0px 0px 15px 3px grey;
      display: inline-block;
      height: 55vh;
      width: 17vw;
      position: relative;
      top: 2vh;
      margin: 3vh;
      animation: goingDown 13s normal;

      + .Box {
        top: 38vh;
        animation: goingUp 15s normal;

        + .Box {
          top: 2vh;
          animation: goingDown 13s normal;

          + .Box {
            position: absolute;
            top: 38vh;
            animation: goingUp 15s normal;
          }
        }
      }
    }

    .logo-initial {
      width: 30vw;
      position: fixed;
      z-index: 10;
      bottom: 40vh;
      left: 35vw;
      display: block;
      text-align: center;
    }

    .enter-landing {
      width: 20vw;
      padding: 10px;
      margin-left: 20%;
      background-color: rgba(255, 255, 255, 0.1);
      border: 0.7px solid #cecece;
      text-align: center;
      display: block;
      animation: showButton 35s normal;
      transition: 0.4s;
      border-radius: 10px;
      text-decoration: none;
    }

    .enter-landing:hover {
      box-shadow: 0px 0px 5px 1px black;
    }

    @keyframes goingDown {
      0% {
        transform: translateY(-200px);
        opacity: 0;
      }
      10% {
        transform: translateY(-200px);
        opacity: 0;
      }
      20%,
      40% {
        transform: translateY(0vh);
        opacity: 1;
      }
      50%,
      100% {
        transform: translateY(-200px);
        opacity: 0;
      }
    }

    @keyframes goingUp {
      0% {
        transform: translateY(200px);
        opacity: 0;
      }
      10% {
        transform: translateY(200px);
        opacity: 0;
      }
      20%,
      40% {
        transform: translateY(0vh);
        opacity: 1;
      }
      50%,
      100% {
        transform: translateY(200px);
        opacity: 0;
      }
    }

    @keyframes showLogo {
      0%,
      10% {
        opacity: 0;
      }
      20%,
      100% {
        opacity: 1;
      }
    }

    @keyframes showButton {
      0%,
      10% {
        opacity: 0;
      }
      20%,
      100% {
        opacity: 1;
      }
    }
  }

  @media screen and (min-width: 1025px) and (max-width: 1200px) {
    display: block;
    width: 100vw;
    height: 100vh;
    overflow: hidden;

    #img1 {
      height: 40vw;
      width: 20vw;
      object-fit: cover;
    }

    .container {
      width: 90vw;
      height: 100vh;
      margin: auto;
      position: relative;
    }

    .Box {
      box-shadow: 0px 0px 15px 3px grey;
      display: inline-block;
      height: 40vw;
      width: 20vw;
      position: relative;
      top: 6%;
      margin: 1.4vw;
      animation: goingDown 13s normal;

      + .Box {
        top: 42%;
        animation: goingUp 15s normal;

        + .Box {
          top: 6%;
          animation: goingDown 13s normal;

          + .Box {
            position: absolute;
            top: 42%;
            animation: goingUp 15s normal;
          }
        }
      }
    }

    .logo-initial {
      width: 30vw;
      position: fixed;
      z-index: 10;
      bottom: 40vh;
      left: 35vw;
      display: block;
      text-align: center;
    }

    .enter-landing {
      width: 20vw;
      padding: 10px;
      margin-left: 20%;
      background-color: rgba(255, 255, 255, 0.1);
      border: 0.7px solid #cecece;
      text-align: center;
      display: block;
      animation: showButton 35s normal;
      transition: 0.4s;
      border-radius: 10px;
      text-decoration: none;
    }

    .enter-landing:hover {
      box-shadow: 0px 0px 5px 1px black;
    }

    @keyframes goingDown {
      0% {
        transform: translateY(-200px);
        opacity: 0;
      }
      10% {
        transform: translateY(-200px);
        opacity: 0;
      }
      20%,
      40% {
        transform: translateY(0vh);
        opacity: 1;
      }
      50%,
      100% {
        transform: translateY(-200px);
        opacity: 0;
      }
    }

    @keyframes goingUp {
      0% {
        transform: translateY(200px);
        opacity: 0;
      }
      10% {
        transform: translateY(200px);
        opacity: 0;
      }
      20%,
      40% {
        transform: translateY(0vh);
        opacity: 1;
      }
      50%,
      100% {
        transform: translateY(200px);
        opacity: 0;
      }
    }

    @keyframes showLogo {
      0%,
      10% {
        opacity: 0;
      }
      20%,
      100% {
        opacity: 1;
      }
    }

    @keyframes showButton {
      0%,
      10% {
        opacity: 0;
      }
      20%,
      100% {
        opacity: 1;
      }
    }
  }

  @media screen and (min-width: 769px) and (max-width: 1024px) {
    display: block;
    width: 100vw;
    height: 100vh;
    overflow: hidden;

    #img1 {
      height: 40vw;
      width: 20vw;
      object-fit: cover;
    }

    .container {
      width: 90vw;
      height: 100vh;
      margin: auto;
      position: relative;
    }

    .Box {
      box-shadow: 0px 0px 15px 3px grey;
      display: inline-block;
      height: 40vw;
      width: 20vw;
      position: relative;
      top: 12%;
      margin: 1.4vw;
      animation: goingDown 13s normal;

      + .Box {
        top: 45%;
        animation: goingUp 15s normal;

        + .Box {
          top: 12%;
          animation: goingDown 13s normal;

          + .Box {
            position: absolute;
            top: 45%;
            animation: goingUp 15s normal;
          }
        }
      }
    }

    .logo-initial {
      width: 30vw;
      position: fixed;
      z-index: 10;
      bottom: 40vh;
      left: 35vw;
      display: block;
      text-align: center;
    }

    .enter-landing {
      width: 20vw;
      padding: 10px;
      margin-left: 20%;
      background-color: rgba(255, 255, 255, 0.1);
      border: 0.7px solid #cecece;
      text-align: center;
      display: block;
      animation: showButton 35s normal;
      transition: 0.4s;
      border-radius: 10px;
      text-decoration: none;
    }

    .enter-landing:hover {
      box-shadow: 0px 0px 5px 1px black;
    }

    @keyframes goingDown {
      0% {
        transform: translateY(-200px);
        opacity: 0;
      }
      10% {
        transform: translateY(-200px);
        opacity: 0;
      }
      20%,
      40% {
        transform: translateY(0vh);
        opacity: 1;
      }
      50%,
      100% {
        transform: translateY(-200px);
        opacity: 0;
      }
    }

    @keyframes goingUp {
      0% {
        transform: translateY(200px);
        opacity: 0;
      }
      10% {
        transform: translateY(200px);
        opacity: 0;
      }
      20%,
      40% {
        transform: translateY(0vh);
        opacity: 1;
      }
      50%,
      100% {
        transform: translateY(200px);
        opacity: 0;
      }
    }

    @keyframes showLogo {
      0%,
      10% {
        opacity: 0;
      }
      20%,
      100% {
        opacity: 1;
      }
    }

    @keyframes showButton {
      0%,
      10% {
        opacity: 0;
      }
      20%,
      100% {
        opacity: 1;
      }
    }
  }

  @media screen and (min-width: 481px) and (max-width: 768px) {
    height: 100vh;
    margin: 0;
    box-sizing: border-box;
    display: flexbox;
    align-items: center;
    justify-content: center;
    background: rgba(140, 140, 140, 0.2);
    overflow: hidden;

    .logo-container {
      width: 100vw;
      height: 100vh;
      align-items: center;
      display: flex;
      flex-direction: row;
      justify-content: center;
      position: fixed;
      z-index: 10;
    }

    .logo-initial {
      width: 30vw;
      position: fixed;
    }

    .container {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;
      height: 90vh;
    }

    #img1,
    .Box {
      height: 40vw;
      width: 40vw;
    }

    #img1 {
      object-fit: cover;
      object-position: 40% 60%;
    }

    .Box {
      display: inline-block;
      margin: 1vw;
      animation: left 11s normal;
      opacity: 0;

      + .Box {
        animation: right 12s normal 1s;

        + .Box {
          animation: left 12s normal 1s;

          + .Box {
            animation: right 11s normal;
          }
        }
      }
    }

    @keyframes left {
      0% {
        opacity: 0;
        transform: translateX(-40vw);
      }
      10% {
        opacity: 0;
        transform: translateX(-40vw);
      }
      20%,
      40% {
        opacity: 1;
        transform: translateX(0vw);
      }

      50%,
      100% {
        opacity: 0;
        transform: translateX(-40vw);
      }
    }

    @keyframes right {
      0% {
        opacity: 0;
        transform: translateX(40vw);
      }
      10% {
        opacity: 0;
        transform: translateX(40vw);
      }
      20%,
      40% {
        opacity: 1;
        transform: translateX(0vw);
      }

      50%,
      100% {
        opacity: 0;
        transform: translateX(40vw);
      }
    }

    @keyframes logoDelay {
      0% {
        opacity: 0;
      }
      10% {
        opacity: 0;
      }
      20%,
      40% {
        opacity: 1;
      }

      50%,
      100% {
        opacity: 0;
      }
    }
  }

  @media screen and (max-width: 480px) {
    height: 100vh;
    margin: 0;
    box-sizing: border-box;
    display: flexbox;
    align-items: center;
    background: rgba(140, 140, 140, 0.2);
    overflow: hidden;

    .logo-container {
      width: 100vw;
      height: 100vh;
      align-items: center;
      display: flex;
      flex-direction: row;
      justify-content: center;
      position: fixed;
      z-index: 10;
    }

    .logo-initial {
      width: 30vw;
      position: fixed;
    }

    .container {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;
      height: 100vh;
    }

    #img1,
    .Box {
      height: 45vw;
      width: 45vw;
    }

    #img1 {
      object-fit: cover;
      object-position: 40% 60%;
    }

    .Box {
      display: inline-block;
      margin: 1vw;
      animation: left 11s normal;
      opacity: 0;

      + .Box {
        animation: right 12s normal 1s;

        + .Box {
          animation: left 12s normal 1s;

          + .Box {
            animation: right 11s normal;
          }
        }
      }
    }

    @keyframes left {
      0% {
        opacity: 0;
        transform: translateX(-40vw);
      }
      10% {
        opacity: 0;
        transform: translateX(-40vw);
      }
      20%,
      40% {
        opacity: 1;
        transform: translateX(0vw);
      }

      50%,
      100% {
        opacity: 0;
        transform: translateX(-40vw);
      }
    }

    @keyframes right {
      0% {
        opacity: 0;
        transform: translateX(40vw);
      }
      10% {
        opacity: 0;
        transform: translateX(40vw);
      }
      20%,
      40% {
        opacity: 1;
        transform: translateX(0vw);
      }

      50%,
      100% {
        opacity: 0;
        transform: translateX(40vw);
      }
    }

    @keyframes logoDelay {
      0% {
        opacity: 0;
      }
      10% {
        opacity: 0;
      }
      20%,
      40% {
        opacity: 1;
      }

      50%,
      100% {
        opacity: 0;
      }
    }
  }
`
