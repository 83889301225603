import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { useProject } from '../hooks/ProjectsContext'
import Navbar from '../components/navbar'
import Line from '../components/line'
import Footer from '../components/footer'
import { Body, ProjectsArea } from '../styles/pages/portfolio'
import NavabarContent from '../components/navbar/Content'
import Modal from '../components/Modal'

const Portfolio: React.FC = () => {
  const { projects } = useProject()
  const [modal, setModal] = useState(false)
  return (
    <>
      {modal && (
        <Modal id="navbar" onClose={() => setModal(false)}>
          <NavabarContent onClose={() => setModal(false)} />
        </Modal>
      )}
      <Body>
        <Navbar ShowModal={() => setModal(true)} />
        <div className="intro">
          <h1>PORTFÓLIO</h1>
        </div>
        <Line />
        <ProjectsArea>
          {projects.map((projeto) => {
            const imgP = projeto.imgs.find((img) => img.type === '1')

            if (imgP) {
              return (
                <Link
                  key={projeto.id}
                  to={`/project/${projeto.id}`}
                  className="link"
                >
                  <div className="box">
                    <img
                      src={imgP.url}
                      className="img"
                      alt={imgP.originalName}
                    />
                    <div className="text">{projeto.title}</div>
                  </div>
                </Link>
              )
            }
            return <div key={projeto.id} style={{ display: 'none' }} />
          })}
        </ProjectsArea>
        <Footer />
      </Body>
    </>
  )
}

export default Portfolio
