import styled from 'styled-components'
import background from '../../images/background.jpg'

export const Container = styled.div`
  display: flex;
  margin: auto;
  background: url(${background}) no-repeat;
  background-size: cover;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  form {
    padding: 40px;
    display: flex;
    flex-direction: column;
    min-height: 350px;
    max-width: 800px;
    min-width: 300px;
    border-radius: 20px;
    margin: auto;
    width: 50vw;
    height: 40vh;
    background: #666360;
    box-shadow: 10px 10px 30px 5px rgba(44, 44, 44, 0.2);
    border-radius: 20px;

    > h1 {
      margin: 0px auto;
      margin-bottom: 10px;
      font-family: 'Alegreya';
      color: #ededed;
      font-size: 64px;
    }
    > button {
      margin: 0px auto;
      height: 40px;
      border-radius: 0px 0px 10px 10px;
      border: 0px;
      min-width: 300px;
      background-color: #ededed;

      :hover {
        color: #474eff;
        border: solid 1px;
        border-color: #474eff !important;
      }
      :active {
        color: #474eff;
        border: solid 2px;
        border-color: #474eff !important;
      }
    }
  }
  @media (max-width: 940px) {
    form {
      border-radius: 0px;
      width: 100vw;
      height: 100vh;
      .Container {
        margin-top: 27vh;
      }
    }
  } ;
`
