/* eslint-disable prettier/prettier */
import React, { useCallback, useRef, useState } from 'react'
import { TiPlusOutline, TiArrowRight, TiArrowLeft } from 'react-icons/ti'
import { Link, Redirect } from 'react-router-dom'
import { Form } from '@unform/web'
import { FormHandles } from '@unform/core'
import * as Yup from 'yup'
import { uuid } from 'uuidv4'
import { FiAlertCircle, FiSave } from 'react-icons/fi'
import Logo from '../../components/Logo'
import { Container, FormsContainer, Navbar, Repositories, Dica } from './styles'
import teste from '../../images/projeto1.jpg'
import { useAuth } from '../../hooks/AuthContext'
import { Projects, useProject } from '../../hooks/ProjectsContext'
import Modal from '../../components/Modal'
import { useToast } from '../../hooks/ToastContext'
import Input from '../../components/Input'
import getValidationErros from '../../utils/getValidationErrors'
import CheckboxInput from '../../components/InputCheckbox'
import Textarea from '../../components/Textarea'
import ModalImg from '../../components/ModalImg'


interface ProjectData {
  title: string
  description: string
  spotligth: Array<CheckboxOption>
  localization: string
  data: string
}
interface CheckboxOption {
  id: string
  value: string
  label: string
}
const Dashboard: React.FC = () => {
  const { signOut } = useAuth()

  const { destaque,RefreshData } = useProject()
  const { addToast } = useToast()
  const { projects, RegisterProjects } = useProject()
  const [Modalvisible, setModalvisible] = useState(false)
  const [Redirection, setRedirection] = useState(false)
  const [createdProjectid, setcreatedProjectid] = useState<Projects>()

  const checkboxOptions: CheckboxOption[] = [
    { id: 'spotlight', value: 'true', label: 'Destaque:' },
  ]
  const formRef = useRef<FormHandles>(null)

  const handleSubmit = useCallback(
    async (data: ProjectData) => {
      try {
        formRef.current?.setErrors({})
        const schema = Yup.object().shape({
          title: Yup.string().required('Title obrigatório'),
          description: Yup.string().required('Descrição nescessária'),
          spotligth: Yup.string().test(
            'len',
            'Tenha somente 4 Destaques',
            (spotligth) => {

              if (destaque.length <= 4) {
                if (destaque.length === 4 && spotligth?.length === 4) {
                  return false
                } return true
              }
              return false
            }
          ),
          localization: Yup.string().required('Localização nescessária'),
          data: Yup.string().required('Data nescessária'),
        })

        await schema.validate(data, {
          abortEarly: false,
        })

        await RegisterProjects({
          title: data.title,
          description: data.description,
          data: data.data,
          localization: data.localization,
          spotligth: data.spotligth.length !== 0,
        }).then((res) => {
          RefreshData()
          setcreatedProjectid(res)
          addToast({
            type: 'sucess',
            title: `Sucesso ao Criar o ${data.title} `,
          })

        })
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErros(err)
          formRef.current?.setErrors(errors)
        } else {
          addToast({
            type: 'error',
            title: `Erro ao tentar Criar ${data.title}`,
            description: `Ocorreu um erro ao criar projeto, ${err}`,
          })
        }
      }
    },
    [RegisterProjects, addToast , destaque,RefreshData]
  )

  function handleUrl(props : Projects) {
      const img = props.imgs.find((Imgs) => Imgs.type === '1')
      if (img) {
        return img.url
      }
      return teste
  }
  function handleRedirect(){
    setRedirection(true)
  }

  return (
    <>
      {(Redirection && createdProjectid) && <Redirect to={`/projects/${createdProjectid.id}`} />}
      {Modalvisible && (
        <Modal
          title="Cadastrar novo Projeto"
          id="criar"
          onClose={() => setModalvisible(false)}
        >
          <FormsContainer>
            <div />
            <Form onSubmit={handleSubmit} ref={formRef}>
              <div>
                <Input isModal name="title" type="text">
                  <strong>Title:</strong>
                </Input>

                <Input isModal name="data" type="text">
                  <strong>Data:</strong>
                </Input>

                <Input isModal name="localization" type="text">
                  <strong>Localização:</strong>
                </Input>

                <CheckboxInput
                  isModal
                  name="spotligth"
                  options={checkboxOptions}
                />
                <Textarea isModal name="description" />
              </div>
              <button
                type="submit"
                className="btn effect04 salvar"
                data-sm-link-text="SAVE"
              >
                <span>
                  <FiSave size={30} />
                </span>
              </button>
            </Form>
          </FormsContainer>
        </Modal>
) }
      {(createdProjectid && Modalvisible)
        && (
          <Modal
            title="Cadastrar Imagem Principal"
            subtitle="Se o projeto não tiver a imagem principal ele não aparecera no site!"
            id="addImg"
            Tip={(
              <Dica isalert title='É aconselhavem a por uma imagem fullHD 1980px x 1080px'>

                <FiAlertCircle color=" #474eff" size={20} />
              </Dica>
)}
            onClose={() =>{setModalvisible(false)}}
          >

            <FormsContainer>
              <div />
              <ModalImg exist={false} type="1" projectId={createdProjectid.id || uuid()} isCreatedModal />

            </FormsContainer>

            <button
              type="button"
              className="btn effect04"
              data-sm-link-text="IMAGENS"
              onClick={handleRedirect}
            >
              <span>
                Adicionar
              </span>
            </button>


          </Modal>
        )}
      <Container>
        <Navbar>
          <TiArrowLeft size={70} onClick={signOut} className="arrow" />



          <div>
            <Logo />
          </div>

          <button
            type="button"
            onClick={() => setModalvisible(true)}
            style={{ cursor: 'pointer' }}
          >
            <TiPlusOutline size="27" style={{ verticalAlign: 'middle' }} />
            <p>Criar</p>
          </button>
        </Navbar>

        <Repositories>
          <div className="div ">
            <div />
            <div>
              <strong>Title</strong>
            </div>
            <div>
              <strong>Localização</strong>
            </div>
            <div>
              <strong>destaque</strong>
            </div>

            <TiArrowRight size="40" style={{ opacity: '0' }} />
          </div>
          {projects.map((Project) => (
            <Link key={Project.id} to={`projects/${Project.id}`}>
              <img src={handleUrl(Project)} alt={Project.title} />
              <div>
                <strong>{Project.title}</strong>
              </div>
              <div>
                <strong>{Project.localization}</strong>
              </div>
              <label htmlFor="spotlitgh" className="label">
                <input
                  id="spotlitgh"
                  type="checkbox"
                  checked={Project.spotligth}
                  readOnly
                />
                <span className="checkmark" />
              </label>

              <TiArrowRight size="60px" />
            </Link>
          ))}
        </Repositories>
      </Container>
    </>
  )
}

export default Dashboard
