import React, { useCallback, useRef, useState } from 'react'
import { FormHandles } from '@unform/core'
import { Form } from '@unform/web'
import * as Yup from 'yup'
import { TiArrowLeft, TiTrash } from 'react-icons/ti'
import { Link, Redirect, useRouteMatch } from 'react-router-dom'
import { FiSave, FiTrash } from 'react-icons/fi'
import Textarea from '../../components/Textarea'
import Input from '../../components/Input'
import Logo from '../../components/Logo'
import ModalImg from '../../components/ModalImg'
import { useProject } from '../../hooks/ProjectsContext'
import { Container, Navbar, Content, ImgContainer } from './styles'
import Upload from '../../components/Upload'
import getValidationErros from '../../utils/getValidationErrors'
import CheckboxInput from '../../components/InputCheckbox'
import Modal from '../../components/Modal'
import { useToast } from '../../hooks/ToastContext'

interface RepositoryParams {
  id: string
}
interface CheckboxOption {
  id: string
  value: string
  label: string
}
interface ProjectData {
  title: string
  description: string
  spotligth: Array<CheckboxOption>
  localization: string
  data: string
}
const ProjectsEditor: React.FC = () => {
  const formRef = useRef<FormHandles>(null)
  const { projects, UpdateProject, DeletarProject, destaque } = useProject()
  const { params } = useRouteMatch<RepositoryParams>()
  const { addToast } = useToast()
  const [Modalvisible, setModalvisible] = useState(false)
  const [redirect, setRedirect] = useState(false)
  const project = projects.find((Projects) => Projects.id === params.id)
  const checkboxOptions: CheckboxOption[] = [
    { id: 'spotlight', value: 'true', label: 'Destaque:' },
  ]
  const handleDelete = useCallback(async () => {
    try {
      if (project) {
        await DeletarProject({ id: project.id }).then(() => {
          setRedirect(true)
          addToast({
            type: 'sucess',
            title: 'Projeto deletado com sucesso !',
          })
        })
      } else {
        addToast({
          type: 'error',
          title: 'Erro ao tentar deletae projeto',
          description: `Ocorreu um erro ao deletar o projeto pois ele nao existe `,
        })
      }
    } catch (err) {
      addToast({
        type: 'error',
        title: 'Erro ao tentar deletar projeto',
        description: `Ocorreu um erro ao deletar projeto ${err}`,
      })
    }
  }, [DeletarProject, addToast, project])
  const handleSubmit = useCallback(
    async (data: ProjectData) => {
      try {
        formRef.current?.setErrors({})
        const schema = Yup.object().shape({
          title: Yup.string().required('Title obrigatório'),
          description: Yup.string().required('Descrição nescessária'),
          spotligth: Yup.string().test(
            'len',
            'Just 4 Destaques',
            (spotligth) => {
              if (project?.spotligth === true) {
                return true
              }
              if (destaque.length <= 4) {
                if (destaque.length === 4 && spotligth?.length === 4) {
                  return false
                }
                return true
              }
              return false
            }
          ),
          localization: Yup.string().required('Localização nescessária'),
          data: Yup.string().required('Data nescessária'),
        })

        await schema.validate(data, {
          abortEarly: false,
        })
        await UpdateProject({
          id: project?.id,
          title: data.title,
          description: data.description,
          data: data.data,
          localization: data.localization,
          spotligth: data.spotligth.length !== 0,
        }).then(() =>
          addToast({
            type: 'sucess',
            title: `Sucesso ao editar o projeto ${data.title}`,
          })
        )
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErros(err)
          formRef.current?.setErrors(errors)
        } else {
          addToast({
            type: 'error',
            title: 'Erro ao tentar editar projeto',
            description: `Ocorreu um erro ao editar projeto, ${err}`,
          })
        }
      }
    },
    [UpdateProject, addToast, project, destaque]
  )
  if (!project) {
    return <Redirect to="/dashboard" />
  }
  const type1 = project.imgs.find((Img) => Img.type === '1')
  const type2 = project.imgs.filter((Img) => Img.type === '2')
  const type3 = project.imgs.filter((Img) => Img.type === '3')

  return (
    <>
      {redirect && <Redirect to="/dashboard" />}
      {Modalvisible && (
        <Modal
          title="Você tem certeza que deseja deletar esse projeto ?"
          id="deletar"
          onClose={() => setModalvisible(false)}
        >
          <button
            type="button"
            onClick={() => setModalvisible(false)}
            className="btn effect04 "
            data-sm-link-text="VOLTAR"
          >
            <span>Cancelar</span>
          </button>

          <button
            type="button"
            onClick={handleDelete}
            className="btn deletar effect04 "
            data-sm-link-text="Deletar"
          >
            <span>
              <FiTrash size={30} />
            </span>
          </button>
        </Modal>
      )}
      <Container>
        <Navbar>
          <Link to="/dashboard">
            <TiArrowLeft size={70} className="arrow" />
          </Link>
          <div>
            <Logo />
          </div>
          <button type="button" onClick={() => setModalvisible(true)}>
            <TiTrash size="27" style={{ verticalAlign: 'middle' }} />

            <p>Deletar</p>
          </button>
        </Navbar>

        {project && (
          <Content>
            <ImgContainer>
              <h1>Imagens</h1>
              <div>
                {type1 ? (
                  <ModalImg
                    exist
                    type={type1.type}
                    projectId={project.id}
                    imgs={type1}
                  /> // eslint-disable-next-line prettier/prettier
                  ) : (<ModalImg exist={false} type="1" projectId={project.id} />)}
                {type2.length >= 2 ? (
                  type2.map((Img) => (
                    <ModalImg
                      key={Img.id}
                      exist
                      type={Img.type}
                      projectId={project.id}
                      imgs={Img}
                    />
                  ))
                ) : type2.length === 1 ? (
                  <>
                    {type2.map((Img) => (
                      <ModalImg
                        key={Img.id}
                        exist
                        type={Img.type}
                        projectId={project.id}
                        imgs={Img}
                      />
                    ))}
                    <ModalImg exist={false} type="2" projectId={project.id} />
                    {
                      // eslint-disable-next-line prettier/prettier
                      }
                  </>
                ) : (
                  <>
                    <ModalImg exist={false} type="2" projectId={project.id} />
                    <ModalImg exist={false} type="2" projectId={project.id} />
                  </>
                )}
                <Upload img={type3} projectId={project.id} />
              </div>
            </ImgContainer>
            <h1>Editor do Projeto</h1>
            <Form ref={formRef} onSubmit={handleSubmit}>
              <div>
                <Input name="title" defaultValue={project.title}>
                  <p>Título: </p>
                </Input>
                <Input name="data" defaultValue={project.data}>
                  <p>Data: </p>
                </Input>
                <Input name="localization" defaultValue={project.localization}>
                  <p>Localização: </p>
                </Input>
                <CheckboxInput
                  isModal={false}
                  name="spotligth"
                  options={checkboxOptions}
                  defaultChecked={project.spotligth === true && true}
                />
                <Textarea
                  name="description"
                  defaultValue={project.description}
                />
              </div>
              <button
                type="submit"
                className="btn effect04 salvar"
                data-sm-link-text="SAVE"
              >
                <span>
                  <FiSave size={30} />
                </span>
              </button>
            </Form>
          </Content>
        )}
      </Container>
    </>
  )
}

export default ProjectsEditor
