/* eslint-disable prettier/prettier */
import styled, { css } from 'styled-components'
import Tooltip from '../Tootip'

interface Focused {
  isFocused: boolean
  isFilled: boolean
  isErrored: boolean
  isModal: boolean
}

export const Container = styled.div<Focused>`
  background: #ededed;
  color: #232129;
  border: 1px solid #adadad;
  border-radius: 10px 10px 0px 0px;
  flex-wrap:wrap;
  display:flex;
  padding: 16px;
  min-width: 200px;
  height: 42vh;
  align-content:center;
  + div {
    border-radius: 0px 0px 0px 0px;
  }
  ${(props) =>
    props.isModal &&
    css`
      flex: 1 1 400px;
      margin: 5px !important;
      border-radius: 20px;
      border-color: #c4c4c4;

      + div {
        margin-left: 10px;
        border-radius: 20px;
      }
      @media(max-width:900px){
        height:22vh ;
     }
     @media(max-width:550px){
        height:22vh ;
     }
    `}
  ${(props) =>
    props.isErrored &&
    css`
      border-color: #c53030;
    `}

  ${(props) =>
    props.isFilled &&
    css`
      color: #474eff;
    `}

    > div{
      margin:auto 0px;
      height:20px;
    }
  textarea {
    font-family: 'Poppins';
    font-size: 1.2rem;
    resize: none;
    flex: 1 1 ;
    height: 100%;
    background: transparent;
    border: 0;
    color: #232129;

    &::placeholder {
      color: #232129;
      font-weight: 500;
      font-family: 'Poppins';
    }
    ${(p) =>
    p.isFocused &&
    css`
        &::placeholder {
          color: #474eff;
        }
        color: #474eff;
        border-color: #474eff;
      `}
  }
  @media(max-width:400px){
    width:100%;
  }

`
export const Error = styled(Tooltip)`
  height: 20px;
  display: block;
  max-width: 50px;
  span {
    background: #c53030 ;
    color: #fff;
    &::before {
      border-color: #c53030 transparent ;
    }
  }
`

