import styled, { css } from 'styled-components'

interface Dropzone {
  isDragActive: boolean
  isDragReject: boolean
}
interface Type {
  type?: string
}
interface Props {
  isClose: boolean
  iscreatedmodal?: boolean
}

export const Container = styled.div<Props>`
  flex: 1 1 300px; /*  Stretching: */
  flex: 0 1 300px;
  height: 300px;
  margin: 5px;
  transition: 400ms;
  background: #383838;

  transition: 400ms;
  ${(props) =>
    props.isClose &&
    css`
      overflow: hidden;
      display: none;
      flex: 0;
      height: 0px;
      width: 0px;
      margin: 0px;
      transform: scale(0);
    `}
  :hover {
    transform: scale(1.1);
  }
  ${(props) =>
    props.iscreatedmodal &&
    css`
      width: 100%;
    `}
`
export const DropContainer = styled.div<Dropzone>`
  border: 1px dashed #ddd;
  cursor: pointer;
  margin: 10px;
  width: calc(100% - 20px);
  height: calc(100% - 20px);
  border-radius: 4px;
  transition: 0.2s ease;
  ${(props) =>
    props.isDragActive &&
    css`
      border-color: #11e100;
    `}
  ${(props) =>
    props.isDragReject &&
    css`
      border-color: #e57878;
    `}
    :hover {
    border-color: #11e100;
  }
`

export const UploadMessage = styled.div<Type>`
  display: flex;
  flex-direction: column;
  color: ${(props) => {
    if (props.type === 'error') {
      return '#e57878'
    }
    if (props.type === 'success') {
      return '#11e100'
    }
    return '#ddd'
  }};
  justify-content: center;
  align-items: center;
  margin: auto;
  width: 100%;
  height: 100%;
  h1 {
    font-size: 25px;
    margin-bottom: 10px;
  }
  :hover {
    color: ${(props) => (props.type === 'error' ? '#e57878' : '#78e890')};
  }
`
