/* eslint-disable prettier/prettier */
import styled, { css } from 'styled-components'
import Tooltip from '../Tootip'

interface Focused {
  isFocused: boolean
  isFilled: boolean
  isErrored: boolean
  isModal: boolean
}

export const Checkbox = styled.div<Focused>`
  background: #ededed;
  border: 1px solid #adadad;
  color: #232129;
  border-radius: 10px 10px 0px 0px;
  padding: 16px;
 + div {

      border-radius: 0px 0px 10px 10px;
    }
    ${(props) =>
    props.isModal &&
    css`
      flex: 1 1;
       flex: 1 1 400px;

      margin: 5px !important;
        border-radius: 10px;
        border-color: #c4c4c4;

        + div {
          margin-left: 10px;
          border-radius: 10px;
        }
      `}

    ${(props) =>
    props.isErrored &&
    css`
        border-color: #c53030;
      `}
  ${(props) =>
    props.isFocused &&
    css`
        color: #474eff;
        border-color: #474eff;
      `}
  ${(props) =>
    props.isFilled &&
    css`
        color: #474eff;
      `}
  > div {
    width:100%;
    grid-template-columns: auto 1fr auto;
    display: grid !important;


  strong {
      position: relative;
    }

    div {
      margin: auto  !important;
      .checkbox {
        --background: #fff;
        --border: #d1d6ee;
        --border-hover: #bbc1e1;
        --border-active: #1e2235;
        --tick: #fff;
        position: relative;

        input,
        svg {
          width: 25px;
          height: 25px;
          display: block;
        }
        input {
          -webkit-appearance: none;
          -moz-appearance: none;
          position: relative;
          outline: none;
          background: var(--background);
          border: none;
          margin: 0;
          padding: 0;
          cursor: pointer;
          border-radius: 4px;
          transition: box-shadow 0.3s;
          box-shadow: inset 0 0 0 var(--s, 1px) var(--b, var(--border));
          &:hover {
            --s: 2px;
            --b: var(--border-hover);
          }
          &:checked {
            --b: var(--border-active);
          }
        }
        svg {
          pointer-events: none;
          fill: none;
          stroke-width: 2px;
          stroke-linecap: round;
          stroke-linejoin: round;
          stroke: var(--stroke, var(--border-active));
          position: absolute;
          top: 0;
          left: 0;
          width: 25px;
          height: 25px;
          transform: scale(var(--scale, 1)) translateZ(0);
        }
        &.path {
          input {
            &:checked {
              --s: 2px;
              transition-delay: 0.4s;
              & + svg {
                --a: 16.1 86.12;
                --o: 102.22;
              }
            }
          }
          svg {
            stroke-dasharray: var(--a, 86.12);
            stroke-dashoffset: var(--o, 86.12);
            transition: stroke-dasharray 0.6s, stroke-dashoffset 0.6s;
          }
        }
      }
      box-sizing: inherit;
      &:before,
      &:after {
        box-sizing: inherit;
      }
    }
  }
`
export const Error = styled(Tooltip)`
  height: 20px;

  span {
    background: #c53030;
    color: #fff;
    &::before {
      border-color: #c53030 transparent;
    }
  }
`
