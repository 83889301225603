/* eslint-disable no-param-reassign */
import React, {
  useEffect,
  useRef,
  InputHTMLAttributes,
  useState,
  useCallback,
} from 'react'
import { useField } from '@unform/core'
import { FiAlertCircle } from 'react-icons/fi'
import { Checkbox, Error } from './styles'

interface Props extends InputHTMLAttributes<HTMLInputElement> {
  name: string
  isModal: boolean
  options: {
    id: string
    value: string
    label: string
  }[]
}
const CheckboxInput: React.FC<Props> = ({
  name,
  options,
  isModal,
  ...rest
}) => {
  const inputRefs = useRef<HTMLInputElement[]>([])
  const [isFocused, setIsFocused] = useState(false)
  const [isFilled, setIsFilled] = useState(false)
  const { fieldName, error, registerField, defaultValue = [] } = useField(name)

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRefs.current,
      getValue: (refs: HTMLInputElement[]) => {
        return refs.filter((ref) => ref.checked).map((ref) => ref.value)
      },
      clearValue: (refs: HTMLInputElement[]) => {
        refs.forEach((ref) => {
          ref.checked = false
        })
      },
      setValue: (refs: HTMLInputElement[], values: string[]) => {
        refs.forEach((ref) => {
          if (values.includes(ref.id)) {
            ref.checked = true
          }
        })
      },
    })
  }, [defaultValue, fieldName, registerField])

  const handleInputFocus = useCallback(() => {
    setIsFocused(true)
  }, [])
  const handleInputBlur = useCallback(() => {
    setIsFilled(() => (isFilled === true ? false : isFilled === false))
    setIsFocused(false)
  }, [isFilled])
  return (
    <Checkbox
      isErrored={!!error}
      isFilled={isFilled}
      isFocused={isFocused}
      isModal={isModal}
    >
      <div>
        <strong>Destaque:</strong>
        <div>
          {options.map((option, index) => (
            <label
              className="checkbox path"
              htmlFor={option.id}
              key={option.id}
            >
              <input
                onFocus={handleInputFocus}
                onBlur={handleInputBlur}
                defaultChecked={defaultValue.find(
                  (dv: string) => dv === option.id
                )}
                ref={(ref) => {
                  inputRefs.current[index] = ref as HTMLInputElement
                }}
                value={option.value}
                type="checkbox"
                id={option.id}
                {...rest}
              />
              <svg viewBox="0 0 21 21">
                <path d="M5,10.75 L8.5,14.25 L19.4,2.3 C18.8333333,1.43333333 18.0333333,1 17,1 L4,1 C2.35,1 1,2.35 1,4 L1,17 C1,18.65 2.35,20 4,20 L17,20 C18.65,20 20,18.65 20,17 L20,7.99769186" />
              </svg>
            </label>
          ))}
        </div>
        {error && (
          <Error title={error}>
            <FiAlertCircle color="#c53030" size={20} />
          </Error>
        )}
      </div>
    </Checkbox>
  )
}
export default CheckboxInput
