import React, { useCallback, useRef } from 'react'
import { FiLock, FiMail } from 'react-icons/fi'
import { Form } from '@unform/web'
import { FormHandles } from '@unform/core'
import * as Yup from 'yup'
import { useAuth } from '../../hooks/AuthContext'
import Input from '../../components/Input'
import { Container } from './styles'
import getValidationErros from '../../utils/getValidationErrors'
import { useToast } from '../../hooks/ToastContext'

interface SignInFormData {
  email: string
  password: string
}

const Login: React.FC = () => {
  const formRef = useRef<FormHandles>(null)
  const { signIn } = useAuth()
  const { addToast } = useToast()
  const handleSubmit = useCallback(
    async (data: SignInFormData) => {
      try {
        formRef.current?.setErrors({})
        const schema = Yup.object().shape({
          email: Yup.string()
            .required('E-mail obrigatório')
            .email('Digite um e-mail válido'),
          password: Yup.string().min(6, 'No mínimo 6 digitos'),
        })

        await schema.validate(data, {
          abortEarly: false,
        })

        await signIn({
          email: data.email,
          password: data.password,
        }).then(() =>
          addToast({
            type: 'info',
            title: 'Bem vinda Rayane !',
            description: 'Tenha um bom dia :)',
          })
        )
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErros(err)
          formRef.current?.setErrors(errors)
        } else {
          addToast({
            type: 'error',
            title: 'Erro na autenticação',
            description: 'Ocorreu um erro ao fazer login, cheque as credencias',
          })
        }
      }
    },
    [signIn, addToast]
  )
  return (
    <Container>
      <Form ref={formRef} onSubmit={handleSubmit}>
        <h1>Login</h1>
        <Input icon={FiMail} name="email" placeholder="E-mail" />
        <Input
          icon={FiLock}
          name="password"
          type="password"
          placeholder="Senha"
        />
        <button type="submit">Entrar</button>
      </Form>
    </Container>
  )
}

export default Login
