import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react'
import api from '../server/api'

interface SignInCreddentials {
  email: string
  password: string
}
interface AuthContextData {
  token: string
  // eslint-disable-next-line @typescript-eslint/ban-types
  user: object
  signIn(credencials: SignInCreddentials): Promise<void>
  signOut(): void
}
interface AuthState {
  token: string
  // eslint-disable-next-line @typescript-eslint/ban-types
  user: object
}
const AuthContext = createContext<AuthContextData>({} as AuthContextData)

const AuthProvider: React.FC = ({ children }) => {
  const [data, setData] = useState<AuthState>(() => {
    return {} as AuthState
  })
  useEffect(() => {
    const token = localStorage.getItem('@ArquiRay:token')
    const user = localStorage.getItem('@ArquiRay:user')
    if (token && user) {
      api
        .get('/sessions', {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then(() => {
          setData({ token, user: JSON.parse(user) })
        })
        .catch(() => {
          localStorage.removeItem('@ArquiRay:token')
          localStorage.removeItem('@ArquiRay:user')
          setData({} as AuthState)
        })
    }
    setData({} as AuthState)
  }, [setData])

  const signIn = useCallback(async ({ email, password }) => {
    const response = await api.post('/sessions', {
      email,
      password,
    })
    const { token, user } = response.data

    localStorage.setItem('@ArquiRay:token', token)
    localStorage.setItem('@ArquiRay:user', JSON.stringify(user))

    setData({ token, user })
  }, [])
  const signOut = useCallback(() => {
    localStorage.removeItem('@ArquiRay:token')
    localStorage.removeItem('@ArquiRay:user')
    setData({} as AuthState)
  }, [])
  return (
    <AuthContext.Provider
      value={{ token: data.token, user: data.user, signIn, signOut }}
    >
      {children}
    </AuthContext.Provider>
  )
}

function useAuth(): AuthContextData {
  const context = useContext(AuthContext)
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider')
  }
  return context
}
export { AuthProvider, useAuth }
