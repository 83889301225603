import styled, { css } from 'styled-components'

interface Props {
  isDeletar: boolean
  isAddImg: boolean
  isNavbar: boolean
}
export const Container = styled.div`
  display: flex;
  position: absolute;
  background: rgba(0, 0, 0, 0.2);
  width: 100%;
  height: 100vh;
  overflow: hidden;
  z-index: 3;
  justify-content: center;
  align-items: center;
  #navbar {
    align-content: left;
    align-items: left;
  }
`
export const Content = styled.div<Props>`
  width: 100%;
  max-width: 1200px;
  margin: 30px;
  height: 90vh;
  border-radius: 20px;
  background-color: #fff;

  > div {
    display: grid;
    grid-template-columns: 55px 1fr 55px;
    text-align: center;
    @media (max-width: 550px) {
      grid-template-columns: 5px 1fr 55px;
    }
    > div {
      margin: 20px auto 0px auto;
      font-family: 'Poppins';
      strong {
        color: #ff9000;
      }
    }
    .exit {
      display: flex;
      position: relative;
      margin: 10px;
      border: none;
      background: transparent;
      transition: 400ms;
      :hover {
        color: red;
      }
    }
  }
  .btn {
    margin-top: 20px;
    // right: calc(-50% + 80px);
    border: none;
    cursor: pointer;
    font-size: 14px;
    font-weight: 400;
    line-height: 45px;
    max-width: 160px;
    position: relative;
    text-decoration: none;
    text-transform: uppercase;
    width: 100%;
    svg {
      vertical-align: middle;
    }
  }
  .btn:hover {
    text-decoration: none;
  }

  /*save_background*/
  .effect04 {
    --uismLinkDisplay: var(--smLinkDisplay, inline-flex);
    display: var(--uismLinkDisplay);
    color: #474eff;
    outline: solid 2px #474eff;
    position: relative;
    transition-duration: 0.4s;
    overflow: hidden;
  }
  .effect04::before,
  .effect04 span {
    margin: 0 auto;
    transition-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
    transition-duration: 0.4s;
  }
  .effect04:hover {
    background-color: #474eff;
  }
  .effect04:hover span {
    -webkit-transform: translateY(-400%) scale(-0.1, 20);
    transform: translateY(-400%) scale(-0.1, 20);
  }

  .effect04::before {
    content: attr(data-sm-link-text);
    color: #fff;
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    -webkit-transform: translateY(500%) scale(-0.1, 20);
    transform: translateY(500%) scale(-0.1, 20);
  }

  .effect04:hover::before {
    letter-spacing: 0.2em;
    -webkit-transform: translateY(0) scale(1, 1);
    transform: translateY(0) scale(1, 1);
  }
  .salvar {
    color: green;
    outline-color: green;
  }
  .salvar:hover {
    background-color: green;
  }
  ${(props) =>
    props.isDeletar &&
    css`
      max-width: 500px;
      max-height: 300px;
      h1 {
        margin-top: 40px;
        font-size: 1.6rem;
      }
      .btn {
        margin-left: 60px;
        margin-top: 40px;
      }
      .deletar {
        color: #fc3030;
        outline-color: #fc3030;
      }

      .deletar:hover {
        background-color: #fc3030;
      }
      @media (max-width: 540px) {
        margin: 0px;
        max-height: 500px;
        .btn {
          margin-top: 100px;
          margin-left: 5px;
          left: 8vw;
        }
        @media (max-width: 540px) {
        margin: 0px;
        max-height: 500px;
        .btn {
          margin-top: 100px;
          margin-left: 5px;
          left: 0vw;
        }
      }
      @media (max-width: 540px) {
        margin: 0px;
        max-height: 100vh;
        .btn {
          margin-top: 40px;
          margin-left: 10vw;
          left: 0vw;
          + .deletar, .btn {
          margin-top: 10px;
          margin-left: 10vw;
          left: 0vw;
        }
        }

      }
    `}

  form {
    align-items: center;
    text-align: center;
    width: 100%;
    font-family: 'Poppins';
    padding: 0px 3%;
    div {
      display: flex;
      flex-wrap: wrap;
      margin: auto;
      align-content: center;
    }
  }
  @media (max-width: 540px) {
    height: 100%;
    width: 100vw;
    border-radius: 0px;
  }
  ${(props) =>
    props.isAddImg &&
    css`
      h1 {
        margin-top: 40px;
        font-size: 1.6rem;
      }
      .btn {
        margin-left: calc(50% - 80px);
        margin-top: 40px;
      }
      .deletar {
        color: #fc3030;
        outline-color: #fc3030;
      }

      .deletar:hover {
        background-color: #fc3030;
      }
      @media (max-width: 650px) {
        margin: 0px;
        > div {
          grid-template-columns: 55px 1fr 55px;
        }
        width: 100vw;
      }
    `}
  ${(props) =>
    props.isNavbar &&
    css`
      position: absolute;
      margin: 0px;
      height: 100%;
      border-radius: 0px;

      width: 65vw;
        left:-100%;
        animation: entry 600ms forwards;

      ul li .navbar-button::before {
        content: '';
        position: absolute;
        width: 100%;
        height: 2px;
        bottom: 0;
        left: 0;
        background-color: #000;
        visibility: hidden;
        transform: scaleX(0);
        transition: all 0.3s ease-in-out 0s;
      }
      ul li .navbar-button:hover::before {
        visibility: visible;
        transform: scaleX(1);
      }
      ul {
        list-style-type: none;
        margin: 0;
        padding: 0;
        overflow: hidden;
        width: 65vw;
        height: 100vh;
        text-align: center;
        position: relative;
        display: flex;
        flex-direction: column;
      }

      ul li {
        margin-top: 7vh;
      }
      ul li .navbar-button {
        text-decoration: none;
        font-size: 1.2rem;
        font-family: 'Open Sans', serif;
        font-weight: 400;
        text-align: center;
        display: block;
        transition: 0.3s;
        color: black;
        position: relative;
      }
      .modalIcon {

        justify-content: space-between;
        width: 150px;
        margin: 30vh auto 0px auto !important;
        > img {
            margin: 0px !important;

            filter: grayscale(100%);
            transition: 0.2s;
          }
        }
        a:hover {
          > img {
            filter: grayscale(0%);
            transform: scale(1.2);
          }
        }
      }

      @media (max-width: 540px) {
        width: 65vw;
        left:-100%;
        animation: entry 600ms forwards;

       }
      }
      @keyframes entry {
        0% {
          left:-100%;
          opacity: 0;
        }
        100% {
          left:0%;
          opacity: 1;
        }
    `}
`
