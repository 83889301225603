import styled from 'styled-components'

export const Body = styled.div`
  height: 100vh;
  width: 100vw;
  background: rgba(250, 250, 250, 1);
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  overflow-y: scroll;
  position: absolute;
  scroll-behavior: smooth;

  ::-webkit-scrollbar {
    display: none;
  }
`

export const Slide2 = styled.div`
  background: rgba(250, 250, 250, 1);
  background-size: 100vw 100vh;
  position: relative;
  animation: body normal 10s;

  @media screen and (min-width: 1201px) {
    height: 100vh;
    width: 100vw;
    .layer {
      width: 100vw;
      height: 100vh;
      background: rgba(245, 245, 245, 0.2);
      padding: 7vw;
      padding-top: 3vw;
    }

    .sld2Image {
      float: left;
      margin-top: 2vw;
      height: 30vw;
      box-shadow: 0px 0px 10px 5px rgba(0, 0, 0, 0.2);
      border-radius: 10px;
      animation: img 15s normal;

      .descImg {
        object-fit: cover;
        object-position: 50% 50%;
        width: 20vw;
        height: 30vw;
        border-radius: 5px;
      }
    }
    .descLabel {
      font-size: 30pt;
      font-family: 'Lato' !important;
      font-weight: 300;
      line-height: 1.1;
      position: relative;
      margin-bottom: 2vw;
      text-align: center;
    }

    .description {
      position: relative;

      width: 65vw;
      text-align: justify;
      margin-left: 24vw;
      font-size: 1.5rem;
      font-family: 'Lato', serif;
      font-weight: 300;
      position: relative;
      animation: desc 16s normal;
      background: rgba(250, 250, 250, 1);
      padding: 0 2vw;
    }

    .btn {
      bottom: 5vw;
      left: 49vw;
      position: absolute;
      animation: btnShow 17s normal;
      .more {
        background: none;
        border: none;
        .btn-link {
          text-decoration: none;
          color: black;
          .arrow {
            font-size: 2vw;
          }
        }
      }
    }

    @keyframes desc {
      0% {
        opacity: 0;
        transform: translateX(50vw);
      }
      10% {
        opacity: 0;
        transform: translateX(50vw);
      }
      20%,
      100% {
        opacity: 1;
        transform: translateX(0vw);
      }
    }

    @keyframes img {
      0% {
        opacity: 0;
        transform: translateY(-20vw);
      }
      10% {
        opacity: 0;
        transform: translateY(-20vw);
      }
      20%,
      100% {
        opacity: 1;
        transform: translateY(0vw);
      }
    }

    @keyframes btnShow {
      0% {
        opacity: 0;
      }
      10% {
        opacity: 0;
      }
      20%,
      100% {
        opacity: 1;
      }
    }

    @keyframes body {
      0% {
        opacity: 0;
        width: 0;
      }
      10% {
        opacity: 0;
        width: 0;
      }
      20%,
      100% {
        opacity: 1;
        width: 100vw;
      }
    }
  }
  @media screen and (min-width: 1025px) and (max-width: 1200px) {
    height: 100vh;
    width: 100vw;
    .layer {
      width: 100vw;
      height: 100vh;
      background: rgba(245, 245, 245, 0.2);
      padding: 7vw;
    }
    .sld2Image {
      float: left;
      margin-top: 8vw;
      height: 30vw;
      box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.8);
      border-radius: 10px;
      animation: img 15s normal;
      .descImg {
        object-fit: cover;
        object-position: 50% 50%;
        width: 20vw;
        height: 30vw;
        border-radius: 10px;
      }
    }
    .descLabel {
      font-size: 30pt;
      font-family: 'Lato' !important;
      font-weight: 300;
      line-height: 1.1;
      position: relative;
      margin-bottom: 2vw;
      text-align: center;
    }

    .description {
      position: relative;

      width: 65vw;
      text-align: justify;
      margin-left: 24vw;
      font-size: 1.2rem;
      font-family: 'Lato', serif;
      font-weight: 300;
      position: relative;
      animation: desc 16s normal;
      background: rgba(250, 250, 250, 1);
      padding: 0 2vw;
    }
    .btn {
      bottom: 0;
      left: 49vw;
      position: absolute;
      animation: btnShow 17s normal;
      .more {
        background: none;
        border: none;
        .btn-link {
          text-decoration: none;
          color: black;
          .arrow {
            font-size: 2vw;
          }
        }
      }
    }
    @keyframes desc {
      0% {
        opacity: 0;
        transform: translateX(50vw);
      }
      10% {
        opacity: 0;
        transform: translateX(50vw);
      }
      20%,
      100% {
        opacity: 1;
        transform: translateX(0vw);
      }
    }
    @keyframes img {
      0% {
        opacity: 0;
        transform: translateY(-20vw);
      }
      10% {
        opacity: 0;
        transform: translateY(-20vw);
      }
      20%,
      100% {
        opacity: 1;
        transform: translateY(0vw);
      }
    }
    @keyframes btnShow {
      0% {
        opacity: 0;
      }
      10% {
        opacity: 0;
      }
      20%,
      100% {
        opacity: 1;
      }
    }
    @keyframes body {
      0% {
        opacity: 0;
        width: 0;
      }
      10% {
        opacity: 0;
        width: 0;
      }
      20%,
      100% {
        opacity: 1;
        width: 100vw;
      }
    }
  }
  @media screen and (min-width: 769px) and (max-width: 1024px) {
    height: 100vh;
    width: 100vw;
    .layer {
      width: 100vw;
      height: 100vh;
      background: rgba(245, 245, 245, 0.2);
      padding: 7vw;
    }
    .sld2Image {
      float: left;
      margin-top: 10vw;
      height: 30vw;
      box-shadow: 0px 0px 10px 5px rgba(0, 0, 0, 0.2);
      border-radius: 10px;
      animation: img 15s normal;
      .descImg {
        object-fit: cover;
        object-position: 50% 50%;
        width: 20vw;
        height: 30vw;
        border-radius: 5px;
      }
    }
    .descLabel {
      font-size: 30pt;
      font-family: 'Lato' !important;
      font-weight: 300;
      line-height: 1.1;
      position: relative;
      margin-bottom: 2vw;
      text-align: center;
    }

    .description {
      position: relative;

      width: 65vw;
      text-align: justify;
      margin-left: 24vw;
      font-size: 1.5rem;
      font-family: 'Lato', serif;
      font-weight: 300;
      position: relative;
      animation: desc 16s normal;
      background: rgba(250, 250, 250, 1);
      padding: 0 2vw;
    }
    .btn {
      bottom: 10vw;
      left: 49vw;
      position: absolute;
      animation: btnShow 17s normal;
      .more {
        background: none;
        border: none;
        .btn-link {
          text-decoration: none;
          color: black;
          .arrow {
            font-size: 2vw;
          }
        }
      }
    }
    @keyframes desc {
      0% {
        opacity: 0;
        transform: translateX(50vw);
      }
      10% {
        opacity: 0;
        transform: translateX(50vw);
      }
      20%,
      100% {
        opacity: 1;
        transform: translateX(0vw);
      }
    }
    @keyframes img {
      0% {
        opacity: 0;
        transform: translateY(-20vw);
      }
      10% {
        opacity: 0;
        transform: translateY(-20vw);
      }
      20%,
      100% {
        opacity: 1;
        transform: translateY(0vw);
      }
    }
    @keyframes btnShow {
      0% {
        opacity: 0;
      }
      10% {
        opacity: 0;
      }
      20%,
      100% {
        opacity: 1;
      }
    }
    @keyframes body {
      0% {
        opacity: 0;
        width: 0;
      }
      10% {
        opacity: 0;
        width: 0;
      }
      20%,
      100% {
        opacity: 1;
        width: 100vw;
      }
    }
  }
  @media screen and (min-width: 481px) and (max-width: 768px) {
    min-height: 100vh;
    width: 100vw;
    .layer {
      position: relative;
      width: 100vw;
      min-height: 100vh;
      /* background: rgba(245, 245, 245, 0.2); */
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    .sld2Image {
      position: relative;
      height: 70vw;
      width: 50vw;
      box-shadow: 0px 0px 10px 5px rgba(0, 0, 0, 0.2);
      border-radius: 10px;
      animation: img 15s normal;
      margin-bottom: 5vw;
      margin-top: 10vw;
      .descImg {
        object-fit: cover;
        object-position: 50% 50%;
        width: 50vw;
        height: 70vw;
        border-radius: 5px;
      }
    }
    .descLabel {
      font-size: 30pt;
      font-family: 'Lato' !important;
      font-weight: 300;
      line-height: 1.1;
      position: relative;
      margin-bottom: 2vw;
      text-align: center;
    }

    .description {
      position: relative;

      text-align: justify;
      margin: 10px 30px;
      font-size: 1.3rem;
      font-family: 'Lato', serif;
      font-weight: 300;
      position: relative;
      animation: desc 16s normal;
      background: rgba(250, 250, 250, 1);
    }
    .btn {
      display: none;
    }
    @keyframes desc {
      0% {
        opacity: 0;
        transform: translateX(50vw);
      }
      10% {
        opacity: 0;
        transform: translateX(50vw);
      }
      20%,
      100% {
        opacity: 1;
        transform: translateX(0vw);
      }
    }
    @keyframes img {
      0% {
        opacity: 0;
        transform: translateY(-20vw);
      }
      10% {
        opacity: 0;
        transform: translateY(-20vw);
      }
      20%,
      100% {
        opacity: 1;
        transform: translateY(0vw);
      }
    }
    @keyframes btnShow {
      0% {
        opacity: 0;
      }
      10% {
        opacity: 0;
      }
      20%,
      100% {
        opacity: 1;
      }
    }
    @keyframes body {
      0% {
        opacity: 0;
        width: 0;
      }
      10% {
        opacity: 0;
        width: 0;
      }
      20%,
      100% {
        opacity: 1;
        width: 100vw;
      }
    }
  }
  @media screen and (max-width: 480px) {
    min-height: 100vh;
    width: 100vw;
    .layer {
      position: relative;
      width: 100vw;
      min-height: 100vh;
      /* background: rgba(245, 245, 245, 0.2); */
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    .sld2Image {
      position: relative;
      height: 70vw;
      width: 50vw;
      box-shadow: 0px 0px 10px 5px rgba(0, 0, 0, 0.2);
      border-radius: 10px;

      animation: img 15s normal;
      margin-bottom: 5vw;
      margin-top: 10vw;
      .descImg {
        object-fit: cover;
        object-position: 50% 50%;
        width: 50vw;
        height: 70vw;
        border-radius: 5px;
      }
    }
    .descLabel {
      font-size: 20pt;
      font-family: 'Lato' !important;
      font-weight: 300;
      line-height: 1.1;
      position: relative;
      margin-bottom: 2vw;
      text-align: center;
    }

    .description {
      position: relative;

      text-align: justify;
      margin: 10px 30px;
      font-size: 1.1rem;
      font-family: 'Lato', serif;
      font-weight: 300;
      position: relative;
      animation: desc 16s normal;
      background: rgba(250, 250, 250, 1);
    }
    .btn {
      display: none;
    }
    @keyframes desc {
      0% {
        opacity: 0;
        transform: translateX(50vw);
      }
      10% {
        opacity: 0;
        transform: translateX(50vw);
      }
      20%,
      100% {
        opacity: 1;
        transform: translateX(0vw);
      }
    }
    @keyframes img {
      0% {
        opacity: 0;
        transform: translateY(-20vw);
      }
      10% {
        opacity: 0;
        transform: translateY(-20vw);
      }
      20%,
      100% {
        opacity: 1;
        transform: translateY(0vw);
      }
    }
    @keyframes btnShow {
      0% {
        opacity: 0;
      }
      10% {
        opacity: 0;
      }
      20%,
      100% {
        opacity: 1;
      }
    }
    @keyframes body {
      0% {
        opacity: 0;
        width: 0;
      }
      10% {
        opacity: 0;
        width: 0;
      }
      20%,
      100% {
        opacity: 1;
        width: 100vw;
      }
    }
  }
`

export const Slide3 = styled.div`
  background: rgba(250, 250, 250, 1);
  background-size: 100vw 100vh;
  position: relative;
  overflow: hidden;
  height: 100vh;
  width: 100vw;
  .layer {
    width: 100vw;
    height: 100vh;
    background: rgba(245, 245, 245, 0.2);
  }

  .specsLabel {
    font-size: 30pt;
    font-family: 'Lato' !important;
    font-weight: 300;
    line-height: 1.1;
    width: 100vw;
    text-align: center;
    margin-top: 4vw;
    position: relative;
  }

  .specs {
    width: 100vw;
    text-align: center;
    height: 100%;
    display: flex;
  }

  .iconArea {
    width: 100%;
    max-width: 1200px;
    display: flex;
    text-align: center;
    margin: 10px auto;
    flex-wrap: wrap;
    height: 70%;
    > div {
      flex: 1 1 90px; /*  Stretching: */
      flex: 1 1 90px;
      position: relative;

      width: 15vw;
      border-radius: 6px;
      margin: 2% 4%;
      img {
        width: 7vw;
      }
    }
  }
  .iconLabel {
    font-size: 2.6vw;
    font-size: 30pt;
    font-family: 'Lato' !important;
    font-weight: 300;
    line-height: 1.1;
  }

  .btn {
    bottom: 10px;
    left: 49vw;
    position: absolute;

    .more {
      background: none;
      border: none;

      .btn-link {
        text-decoration: none;
        color: black;

        .arrow {
          font-size: 40px;
        }
      }
    }
  }

  @media screen and (max-width: 480px) {
    .specsLabel {
      font-size: 5.6vw;
      width: 100vw;
      text-align: center;
      margin-top: 4vw;
      position: relative;
    }

    .specs {
      width: 100vw;
      height: 50vw;
      text-align: center;
      padding-top: 4vw;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    .iconArea {
      width: 100%;
      max-width: 1200px;
      display: flex;
      text-align: center;
      margin: 10px auto;
      flex-wrap: wrap;
      height: 100%;
      > div {
        flex: 1 1 90px; /*  Stretching: */
        flex: 1 1 90px;
        position: relative;
        border-radius: 6px;
        margin: 20px 10%;
        img {
          width: 50px;
        }
      }
    }

    .iconLabel {
      font-size: 4vw;
    }
  }
`
