import styled from 'styled-components'

export const Container = styled.footer`
  bottom: 0;
  position: relative;
  width: 100vw;
  padding: 1.5vw;
  text-align: center;
  font-size: 0.8rem;
  font-weight: bold;
  font-family: 'Barlow', serif;
  background: rgba(220, 220, 220, 0.7);
  word-spacing: 0.1vw;
  h1 {
    font-size: 1.8rem;
  }
  h2 {
    margin-bottom: 30px;
  }
  > div {
    width: 140px;
    display: flex;
    justify-content: space-between;
    margin: auto;
    a {
      img {
        width: 30px;
        transition: 0.3s;
        :hover {
          transform: scale(1.5);
        }
      }
    }
  }
  p {
    margin-top: 60px;
  }
`
