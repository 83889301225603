import styled, { css } from 'styled-components'

interface imgs {
  url: string
  isDeletado: boolean
  iscreatedmodal?: boolean
  type: string
}
export const Container = styled.div<imgs>`
  display: flex;
  flex: 1 1 300px; /*  Stretching: */
  flex: 0 1 300px;
  height: 300px;
  margin: 5px;
  overflow: hidden;
  cursor: pointer;
  background-image: url('${(prop) => prop.url}');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  transition: 400ms;
  :hover {
    transform: scale(1.1);
  }

  > form {
    margin: 0px;
    padding: 0;
    width: 100%;
    height: 300px;
    transition: 600ms;
    display: flex;

    > h1 {
      margin: 0px;
      display: flex;
      position: relative;
      font-size: 1.3rem;
      color: white;
      top: 300px;
      left: 30px;
      transition: 600ms;
    }
    > button {
      height: 40px;
      display: flex;
      position: relative;
      border: 1px solid #e57878;
      border-radius: 5px;
      padding: 10px;
      color: #fdd;
      background-color: #e57878;
      top: 300px;
      left: calc(100% - 240px);
      transition: 600ms;

      :hover {
        border: 1px solid #fc3030;
        border-radius: 5px;
        padding: 10px;
        color: white;

        background-color: #fc3030;
      }
    }
    :hover {
      box-shadow: inset 0px -60px 20px rgba(0, 0, 0, 0.2);
      transform: scale(1.1);
      button {
        transform: translateY(-60px);
      }
      h1 {
        transform: translateY(-50px);
      }
    }
  }

  ${(props) =>
    props.type === '1' &&
    css`
      > form {
        margin: 0px;
        width: 100%;

        button {
          left: calc(100% - 200px);
        }
      }
    `}
  ${(props) =>
    props.type === '3' &&
    css`
      > form {
        margin: 0px;
        width: 100%;

        button {
          left: calc(100% - 180px);
        }
      }
    `}

  ${(props) =>
    props.isDeletado &&
    css`
      flex: 0;
      display: none;
      margin: 0;
    `}
  ${(props) =>
    props.iscreatedmodal &&
    css`
      margin: 0;
      width: 100%;

      height: 50vh;
      form {
        padding: 0;
        height: 100%;
        width: 100%;
        h1 {
          top: calc(50% + 15px);
        }
        button {
          top: calc(50% + 20px);
        }
        :hover {
          transform: scale(1);
        }
      }
      @media (max-width: 540) {
        height: 20vh;
      }
    `}
`
