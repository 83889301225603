import React, { useCallback, useState } from 'react'
import { FiTrash } from 'react-icons/fi'
import { Form } from '@unform/web'
import { Container } from './styles'
import api from '../../server/api'
import { useToast } from '../../hooks/ToastContext'

interface Img {
  type: string
  id: string
  url: string
  isClose(): void
  iscreatedmodal?: boolean
}
const Preview: React.FC<Img> = ({
  iscreatedmodal = false,
  url,
  id,
  type,
  isClose,
}) => {
  const [Deletado, setDeletado] = useState(false)
  const { addToast } = useToast()
  const handleDelete = useCallback(async () => {
    try {
      const token = localStorage.getItem('@ArquiRay:token')
      await api
        .delete(`/imgs/${id}`, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then(() => {
          isClose()
          setDeletado(true)
          addToast({
            type: 'sucess',
            title: `Sucesso ao Deletar imagem`,
          })
        })
    } catch (err) {
      addToast({
        type: 'error',
        title: `Erro ao Deletar imagem`,
        description: `Erro de ${err}`,
      })
    }
  }, [addToast, id, isClose])
  function handleType() {
    if (type === '1') {
      return <h1>Principal</h1>
    }
    if (type === '2') {
      return <h1>Secondaria</h1>
    }
    return <h1>Outras</h1>
  }
  return (
    <Container
      url={url}
      type={type}
      isDeletado={Deletado}
      iscreatedmodal={iscreatedmodal}
    >
      <Form onSubmit={handleDelete}>
        {handleType()}
        <button type="submit">
          <span>
            <FiTrash />
            Deletar
          </span>
        </button>
      </Form>
    </Container>
  )
}

export default Preview
