import React from 'react'
import { Content } from './styles'

const LogoHomePage: React.FC = () => {
  return (
    <Content id="Camada_1" x="0" y="0" viewBox="0 260 600 300">
      <g className="line">
        <path
          className="Bottom"
          fill="none"
          strokeWidth="10"
          d="M43.94 510.61H240"
        />
        <path
          className="curveOne"
          fill="none"
          strokeWidth="10"
          d="M244.54 510.51c.56.06 1.13.09 1.71.09 6.58 0 12.19-4.2 14.28-10.06"
        />
        <path
          className="middle"
          fill="none"
          strokeWidth="10"
          d="M260.41 500.94l74.35-161.49"
        />
        <path
          className="curveTwo"
          fill="none"
          strokeWidth="10"
          d="M350.37 330.49c-.56-.06-1.13-.09-1.71-.09-6.58 0-12.19 4.2-14.28 10.06"
        />

        <path
          className="Top"
          fill="none"
          strokeWidth="10"
          d="M350  330.53H546.11"
        />
      </g>
      <g className="CA">
        <path
          className="C"
          strokeWidth="5"
          d="M199.66,391.68q15.6,0,26,10.36a.78.78,0,0,1,.23.57.71.71,0,0,1-.8.8.78.78,0,0,1-.57-.23q-9.9-10-24.83-10t-24.88,10q-10,10-10,24.89t10,24.89q10,10,24.88,10t24.83-10a.78.78,0,0,1,.57-.23.71.71,0,0,1,.8.8.78.78,0,0,1-.23.57q-10.37,10.36-26,10.36t-25.91-10.42Q163.33,443.5,163.33,428t10.42-25.91Q184.18,391.67,199.66,391.68Z"
        />
        <path
          className="A"
          strokeWidth="5"
          d="M376.68,388a.82.82,0,0,1,.8.45l30.41,71.19a.33.33,0,0,1,.11.23.7.7,0,0,1-.79.79.73.73,0,0,1-.69-.45l-29.84-69.48L347,460.25a.73.73,0,0,1-.68.45.7.7,0,0,1-.8-.79.31.31,0,0,1,.12-.23L376,388.49A.73.73,0,0,1,376.68,388Z"
        />
      </g>
      <g className="Cezario">
        <path d="M91.48,394.36a10.13,10.13,0,0,1,7.47,3,.25.25,0,0,1,.06.17.2.2,0,0,1-.23.23.24.24,0,0,1-.16-.07,10,10,0,1,0,0,14.29.2.2,0,0,1,.16-.07.2.2,0,0,1,.23.23.25.25,0,0,1-.06.17,10.56,10.56,0,1,1-7.47-17.93Z" />
        <path d="M108.09,394.36H122.7c.15,0,.23.07.23.21s-.08.22-.23.22H108.09c-.16,0-.23-.07-.23-.22S107.93,394.36,108.09,394.36Zm0,10.22h13.14a.21.21,0,0,1,.23.23.2.2,0,0,1-.23.23H108.09c-.16,0-.23-.07-.23-.23A.2.2,0,0,1,108.09,404.58Zm0,10.26H122.7c.15,0,.23.07.23.21s-.08.22-.23.22H108.09c-.16,0-.23-.07-.23-.22S107.93,414.84,108.09,414.84Z" />
        <path d="M145.77,394.36a.21.21,0,0,1,.23.23.19.19,0,0,1-.06.13l-14.36,20.12h14.19c.15,0,.23.07.23.21s-.08.22-.23.22H131.16a.2.2,0,0,1-.23-.23.28.28,0,0,1,0-.13l14.38-20.12-14.18,0a.23.23,0,0,1,0-.46Z" />
        <path d="M163,394.36a.23.23,0,0,1,.23.13L172,415a.13.13,0,0,1,0,.07.21.21,0,0,1-.23.23.22.22,0,0,1-.2-.13l-8.59-20-8.55,20a.22.22,0,0,1-.19.13A.2.2,0,0,1,154,415a.11.11,0,0,1,0-.07l8.75-20.48A.2.2,0,0,1,163,394.36Zm3.08-10a.2.2,0,0,1,.23.23.19.19,0,0,1-.13.19l-5.83,2.92-.1,0a.2.2,0,0,1-.23-.23.22.22,0,0,1,.13-.19l5.84-2.92Z" />
        <path d="M180.24,394.36h7.31a10,10,0,0,1,4.85,1.08,5.29,5.29,0,0,1,0,9.21,9.12,9.12,0,0,1-3.21,1l7.31,9.28a.28.28,0,0,1,0,.13c0,.15-.07.23-.2.23a.21.21,0,0,1-.19-.1l-7.44-9.47c-.4,0-.78.06-1.15.06h-7.11V415c0,.15-.07.23-.21.23s-.22-.08-.22-.23v-9.51c0-.13.08-.19.23-.19h7.31a7.31,7.31,0,0,0,.82-.07l-8.32-10.55a.22.22,0,0,1,0-.13A.21.21,0,0,1,180.24,394.36Zm.43.43,8.22,10.45a8.51,8.51,0,0,0,3.28-1,4.85,4.85,0,0,0,0-8.42,9.46,9.46,0,0,0-4.65-1Z" />
        <path d="M204.59,394.59a.21.21,0,0,1,.23-.23c.13,0,.19.08.19.23V415c0,.15-.07.23-.21.23s-.21-.08-.21-.23Z" />
        <path d="M223.23,394.36a10.47,10.47,0,0,1,7.59,3.06,10.29,10.29,0,0,1,0,14.78,10.44,10.44,0,0,1-7.59,3.07,10,10,0,0,1-5.26-1.41,9.82,9.82,0,0,1-3.63-3.8,11.23,11.23,0,0,1,0-10.49,9.82,9.82,0,0,1,3.63-3.8A10,10,0,0,1,223.23,394.36Zm0,.43a9.23,9.23,0,0,0-7,2.9,10.55,10.55,0,0,0,0,14.25,9.26,9.26,0,0,0,7,2.9,10,10,0,0,0,7.29-2.93,9.89,9.89,0,0,0,0-14.19A10,10,0,0,0,223.23,394.79Z" />
      </g>
      <g className="Arquitetura">
        <path d="M275.46,394.36a.23.23,0,0,1,.23.13L284.44,415a.11.11,0,0,1,0,.07.21.21,0,0,1-.23.23.23.23,0,0,1-.2-.13l-8.58-20-8.55,20a.23.23,0,0,1-.2.13.21.21,0,0,1-.23-.23.11.11,0,0,1,0-.07l8.75-20.48A.21.21,0,0,1,275.46,394.36Z" />
        <path d="M292.73,394.36H300a9.93,9.93,0,0,1,4.84,1.08,5.29,5.29,0,0,1,0,9.21,9.12,9.12,0,0,1-3.21,1l7.31,9.28a.28.28,0,0,1,0,.13c0,.15-.06.23-.19.23a.23.23,0,0,1-.2-.1l-7.44-9.47a11.17,11.17,0,0,1-1.14.06h-7.12V415c0,.15-.07.23-.21.23s-.21-.08-.21-.23v-9.51c0-.13.07-.19.23-.19H300a7.18,7.18,0,0,0,.81-.07l-8.32-10.55a.31.31,0,0,1,0-.13A.2.2,0,0,1,292.73,394.36Zm.42.43,8.23,10.45a8.57,8.57,0,0,0,3.28-1,4.86,4.86,0,0,0,0-8.42,9.51,9.51,0,0,0-4.65-1Z" />
        <path d="M326.09,394.36a10.44,10.44,0,0,1,7.58,3.06,10,10,0,0,1,3.13,7.39,10.24,10.24,0,0,1-4.13,8.26l3.8,3.28a.2.2,0,0,1,.07.16c0,.15-.07.23-.2.23a.23.23,0,0,1-.16-.06l-3.87-3.35a10.66,10.66,0,0,1-6.22,1.94,10,10,0,0,1-5.26-1.41,9.77,9.77,0,0,1-3.64-3.8,11.23,11.23,0,0,1,0-10.49,9.77,9.77,0,0,1,3.64-3.8A10,10,0,0,1,326.09,394.36Zm0,.43a9.23,9.23,0,0,0-7,2.9,10.55,10.55,0,0,0,0,14.25,9.27,9.27,0,0,0,7,2.9A10.12,10.12,0,0,0,332,413l-6-5.15a.21.21,0,0,1-.06-.16c0-.15.06-.23.2-.23a.21.21,0,0,1,.16.07l6.06,5.21a9.83,9.83,0,0,0,4.06-8,9.59,9.59,0,0,0-3-7.09A10,10,0,0,0,326.09,394.79Z" />
        <path d="M343.91,394.36c.13,0,.2.08.2.23v12.52a7.73,7.73,0,1,0,15.46,0V394.59a.21.21,0,0,1,.23-.23.2.2,0,0,1,.23.23v12.52a8.18,8.18,0,0,1-16.35,0V394.59A.21.21,0,0,1,343.91,394.36Z" />
        <path d="M369.18,394.59c0-.15.07-.23.22-.23s.2.08.2.23V415c0,.15-.07.23-.21.23s-.21-.08-.21-.23Z" />
        <path d="M377.89,394.36h17.53a.22.22,0,0,1,.22.12.26.26,0,0,1,0,.21.25.25,0,0,1-.22.1h-8.55V415c0,.11,0,.18-.1.21a.26.26,0,0,1-.21,0,.2.2,0,0,1-.12-.21V394.79h-8.55a.21.21,0,0,1-.19-.1.17.17,0,0,1,0-.21A.2.2,0,0,1,377.89,394.36Z" />
        <path d="M403.94,394.36h14.62c.15,0,.23.07.23.21s-.08.22-.23.22H403.94c-.15,0-.23-.07-.23-.22S403.79,394.36,403.94,394.36Zm0,10.22h13.14a.21.21,0,0,1,.23.23.2.2,0,0,1-.23.23H403.94a.2.2,0,0,1-.23-.23A.21.21,0,0,1,403.94,404.58Zm0,10.26h14.62c.15,0,.23.07.23.21s-.08.22-.23.22H403.94c-.15,0-.23-.07-.23-.22S403.79,414.84,403.94,414.84Z" />
        <path d="M427,394.36h17.53a.21.21,0,0,1,.22.12.26.26,0,0,1,0,.21.23.23,0,0,1-.22.1H436V415c0,.11,0,.18-.1.21a.26.26,0,0,1-.21,0c-.08,0-.11-.1-.11-.21V394.79H427a.23.23,0,0,1-.19-.1.17.17,0,0,1,0-.21A.21.21,0,0,1,427,394.36Z" />
        <path d="M451.91,394.36c.14,0,.2.08.2.23v12.52a7.74,7.74,0,1,0,15.47,0V394.59a.2.2,0,0,1,.23-.23.21.21,0,0,1,.23.23v12.52a8.18,8.18,0,0,1-16.36,0V394.59A.21.21,0,0,1,451.91,394.36Z" />
        <path d="M477.41,394.36h7.3a9.94,9.94,0,0,1,4.85,1.08,5.29,5.29,0,0,1,0,9.21,9.12,9.12,0,0,1-3.21,1l7.31,9.28a.28.28,0,0,1,0,.13c0,.15-.06.23-.19.23a.23.23,0,0,1-.2-.1l-7.44-9.47a11.22,11.22,0,0,1-1.15.06H477.6V415c0,.15-.07.23-.21.23s-.21-.08-.21-.23v-9.51c0-.13.07-.19.23-.19h7.3a7.13,7.13,0,0,0,.82-.07l-8.32-10.55a.31.31,0,0,1,0-.13A.2.2,0,0,1,477.41,394.36Zm.42.43,8.23,10.45a8.57,8.57,0,0,0,3.28-1,4.86,4.86,0,0,0,0-8.42,9.53,9.53,0,0,0-4.66-1Z" />
        <path d="M509.55,394.36a.23.23,0,0,1,.23.13L518.53,415a.11.11,0,0,1,0,.07.2.2,0,0,1-.23.23.22.22,0,0,1-.19-.13l-8.59-20-8.55,20a.22.22,0,0,1-.2.13.21.21,0,0,1-.23-.23.09.09,0,0,1,0-.07l8.75-20.48A.2.2,0,0,1,509.55,394.36Z" />
      </g>
    </Content>
  )
}
export default LogoHomePage
