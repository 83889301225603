import styled from 'styled-components'
import background from '../../images/background.jpg'

export const Container = styled.div`
  display: flex;
  margin: auto;
  background: url(${background}) no-repeat;
  background-size: cover;
  width: 100vw;
  height: 100vh;
  overflow-x: hidden;
`
export const Navbar = styled.header`
  position: fixed;
  width: 100vw;
  z-index: 2;
  height: 80px;
  display: flexbox;
  justify-content: space-between;
  box-shadow: 10px 10px 10px 1px rgba(44, 44, 44, 0.1);

  background: rgba(255, 255, 255, 1);
  .arrow {
    width: 70px;
    height: 100%;
    :hover {
      color: #474eff;
      border: solid 2px;
      border-color: #474eff;
    }
    :active {
      background: rgba(71, 78, 255, 0.1);
    }
  }
  div {
    max-width: 150px;
    height: 100%;
  }
  button {
    display: flex;
    font-size: 1.5rem;
    border: 0px;
    height: 100%;
    align-items: center;
    justify-content: center;
    background: rgba(227, 228, 255, 0.1);
    width: 12%;
    padding-right: 1vw;
    p {
      padding-left: 1vw;
    }
    :hover {
      color: #474eff;
      border: solid 2px;
      border-color: #474eff;
    }
    :active {
      background: rgba(71, 78, 255, 0.1);
    }
  }
  @media (max-width: 540px) {
    .arrow {
      width: 50px;
    }
    button {
      width: 22%;
      font-size: 23px;
    }
    > div {
      margin-top: 20px;
      width: 80px;
    }
  }
`
export const Content = styled.div`
  z-index: 1;
  margin-top: 100px;
  width: 100%;
  h1 {
    text-align: center;
    margin: 10px 0px;
  }
  font-family: 'Poppins';
  > form {
    width: 100%;
    max-width: 800px;
    margin: 20px auto;
    height: 630px;

    input {
      margin-left: 10px;
    }
  }
  .btn {
    margin-top: 40px;
    margin-left: calc(50% - 80px);
    border: none;
    cursor: pointer;
    font-size: 14px;
    font-weight: 400;
    line-height: 45px;
    max-width: 160px;
    position: relative;
    text-decoration: none;
    text-transform: uppercase;
    width: 100%;
    svg {
      vertical-align: middle;
    }
  }
  .btn:hover {
    text-decoration: none;
  }
  /*save_background*/
  .effect04 {
    --uismLinkDisplay: var(--smLinkDisplay, inline-flex);
    display: var(--uismLinkDisplay);
    color: #474eff;
    outline: solid 2px #474eff;
    position: relative;
    transition-duration: 0.4s;
    overflow: hidden;
  }
  .effect04::before,
  .effect04 span {
    margin: 0 auto;
    transition-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
    transition-duration: 0.4s;
  }
  .effect04:hover {
    background-color: #474eff;
  }
  .effect04:hover span {
    -webkit-transform: translateY(-400%) scale(-0.1, 20);
    transform: translateY(-400%) scale(-0.1, 20);
  }
  .salvar {
    color: green;
    outline-color: green;
  }
  .salvar:hover {
    background-color: green;
  }
  .effect04::before {
    content: attr(data-sm-link-text);
    color: #fff;
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    -webkit-transform: translateY(500%) scale(-0.1, 20);
    transform: translateY(500%) scale(-0.1, 20);
  }

  .effect04:hover::before {
    letter-spacing: 0.2em;
    -webkit-transform: translateY(0) scale(1, 1);
    transform: translateY(0) scale(1, 1);
  }
  @media (max-width: 540px) {
    > form {
      height: 85vh;
    }
  }
`
export const ImgContainer = styled.div`
  border: solid 1px #111;
  width: 100%;
  max-width: 1300px;
  margin: auto;
  div {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
`
