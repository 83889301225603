import styled from 'styled-components'

export const NavbarHeader = styled.div`
  position: relative;
  top: 0;
  background-color: #0e1111;

  .navbar {
    animation: NavbarShow 7s normal;
    height: 70px;
  }

  .menu {
    display: none;
  }
  .navbar ul li .navbar-button::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 2px;
    bottom: 0;
    left: 0;
    background-color: #fff;
    visibility: hidden;
    transform: scaleX(0);
    transition: all 0.3s ease-in-out 0s;
  }

  .navbar ul li .navbar-button:hover::before {
    visibility: visible;
    transform: scaleX(1);
  }

  .navbar ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    overflow: hidden;
    width: 100vw;
    text-align: center;
  }

  .navbar .navbarLogo {
    left: 45vw;
    width: 130px;
    position: absolute;
  }

  .navbar ul li {
    float: left;
    margin-left: 3vw;
    margin-top: 25px;
    + li + li + li + li {
      float: right;
      margin-right: 4vw;
      + li {
        margin-right: 0px;
      }
    }
  }

  .navbar ul li .navbar-button {
    text-decoration: none;
    font-size: 1.2rem;
    font-family: 'Lato', serif;
    font-weight: 400;
    text-align: center;
    display: block;
    transition: 0.3s;
    color: white;
    position: relative;
  }

  .iconAreaNavbar {
    display: flex;
    justify-content: space-between;
    width: 100px;
    > a {
      margin-left: 5px;
      > svg {
        color: white;
        border: 0px;
        transition: 0.2s;
      }
    }
    a:hover {
      > svg {
        transform: scale(1.2);
      }
    }
  }
  @keyframes NavbarShow {
    0% {
      opacity: 0;
    }
    10% {
      opacity: 0;
    }
    20%,
    100% {
      opacity: 1;
    }
  }

  @media (max-width: 925px) {
    .menu {
      display: flex;
    }
    .navbar ul li {
      + li {
        display: none;
      }
    }
  }
  @media (max-width: 600px) {
    .navbar .navbarLogo {
      position: absolute;
      left: 40vw;
      width: 130px;
    }
  }
  @media (max-width: 450px) {
    .navbar .navbarLogo {
      position: absolute;
      left: 37vw;
      width: 130px;
    }
  }
`
