/* eslint-disable prettier/prettier */
import React, { useCallback, useState } from 'react'
import { useDropzone } from 'react-dropzone'
import { Container, DropContainer, UploadMessage, } from './styles'
import { useAuth } from '../../hooks/AuthContext'
import Preview from '../Preview'
import api from '../../server/api'
import { useToast } from '../../hooks/ToastContext'
import { useProject } from '../../hooks/ProjectsContext'

interface Props {
  projectId: string
  img: Array<UploaddImg>
}
interface UploaddImg {
  file?: File
  id: string
  projectId: string
  key: string
  originalName: string
  type: string
  url: string
}
const Upload: React.FC<Props> = ({ projectId, img }) => {
  const [imgs, setImgs] = useState<UploaddImg[]>(() => img)
  const {RefreshData} = useProject()
  const { token } = useAuth()
  const { addToast } = useToast()
  const {
    isDragActive,
    isDragReject,
    getRootProps,
    getInputProps,
  } = useDropzone({
    accept: 'image/*',
    maxSize: 750000,
    onDropAccepted: async (acceptedFiles) => {
      acceptedFiles.forEach(upload)

    },
    onDropRejected: (err)=>{
      err.forEach((error)=>
      addToast({
        type: 'error',
        title: `Erro esse arquivo nao foi aceito!`,
        description: `O arquivo ${error.file.name} é muito grande ou nao faz é uma imagem!}`  ,
      })
      )}
  })

  const upload = useCallback(async (uploadFiles) => {
    try {
      const data = new FormData()
      data.append('file', uploadFiles, uploadFiles.name)
      await api
        .post(`imgs/${projectId}/3`, data, {
          headers: {
            Authorization: `Bearer ${token}`
          },
        }).then((response) => {
          const res = response.data
          RefreshData()
          setImgs((uplod) => uplod.concat([res]))
          addToast({
            type: 'sucess',
            title: `Sucesso ao Salvar imagem ${res.originalName} `,
          })
        }).catch((err) =>
            addToast({
            type: 'error',
            title: `Error ao Salvar imagem ${err} `,
          }))
    } catch (err) {
      addToast({
        type: 'error',
        title: `Erro ao tentar salvar a ${uploadFiles.name} `,
        description: err,
      })
    }
  }, [addToast, projectId, token,RefreshData ])

  return (
    <>
      <Container isClose={false}>
        <DropContainer
          {...getRootProps()}
          isDragActive={isDragActive}
          isDragReject={isDragReject}
        >
          <input {...getInputProps({ className: 'dropzone' })} />
          {!isDragActive ? (
            <UploadMessage>
              <h1>Outras Imagens</h1>
              <p>Solte o Arquivo aqui</p>
              <p>(Only images will be accepted)</p>
            </UploadMessage>
          ) : isDragReject ? (
            <UploadMessage type="error">
              <h1>Outras Imagens</h1>
              <p>Arquivo nao suportado</p>
              <p>(Only *.jpeg and *.png images will be accepted)</p>
            </UploadMessage>
          ) : (
            <UploadMessage type="success">
              <h1>Outras Imagens</h1>
              <p>Solte o arquivo</p>
              <p>(Only *.jpeg and *.png images will be accepted)</p>
            </UploadMessage>
              )}
        </DropContainer>
      </Container>
      {imgs.map((Img) => {
        return (
          <Preview
            key={Img.id}
            id={Img.id}
            type={Img.type}
            url={Img.url}
            isClose={() => { return {} }}

          />
        )
      })}
    </>
  )
}

export default Upload
