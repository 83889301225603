import styled from 'styled-components'
import Tooltip from '../../components/Tootip'
import background from '../../images/background.jpg'

export const Container = styled.div`
  display: flex;
  margin: auto;
  background: url(${background}) no-repeat;
  background-size: cover;
  width: 100vw;
  overflow-x: hidden;
  height: 100vh;
`
export const Navbar = styled.header`
  position: fixed;
  width: 100%;
  z-index: 2;
  height: 80px;
  display: flexbox;
  justify-content: space-between;
  box-shadow: 10px 10px 10px 1px rgba(44, 44, 44, 0.1);

  background: rgba(255, 255, 255, 1);
  .arrow {
    width: 70px;
    height: 100%;
    :hover {
      color: #474eff;
      border: solid 2px;
      border-color: #474eff;
    }
    :active {
      background: rgba(71, 78, 255, 0.1);
    }
  }
  div {
    max-width: 150px;
    height: 100%;
  }
  button {
    display: flex;
    font-size: 2rem;
    border: 0px;
    height: 100%;
    align-items: center;
    justify-content: center;
    background: rgba(227, 228, 255, 0.1);
    width: 10%;

    p {
      margin: 5px;
    }
    :hover {
      color: #474eff;
      border: solid 2px;
      border-color: #474eff;
    }
    :active {
      background: rgba(71, 78, 255, 0.1);
    }
  }
  @media (max-width: 540px) {
    .arrow {
      width: 50px;
    }
    button {
      width: 20%;
      font-size: 22px;
    }
    > div {
      margin-top: 20px;
      width: 80px;
    }
  }
`
export const Repositories = styled.div`
  margin-top: 80px;
  z-index: 1;
  margin: 100px auto 0px;
  width: 90%;
  max-width: 1200px;

  a,
  .div {
    align-content: center;
    background: #dbdbdb;
    border: solid 2px #a4a4a5;
    border-radius: 5px;
    width: 95%;
    margin: auto;
    height: 70px;
    padding: 4px;
    text-decoration: none;
    display: grid;
    grid-template-columns: auto 1fr 1fr 1fr auto;
    text-align: center;

    cursor: default;
    & + a {
      transition: transform 0.2s;

      margin-top: 16px;
      cursor: pointer;
      align-content: center;
    }

    & + a:hover {
      transform: translateX(10px);
      border-color: #474eff;
    }
    :hover svg {
      color: #474eff;
    }
    img {
      margin: auto;
      width: 5vw;
      max-width: 64px;
      height: 5vw;
      max-height: 64px;
      border-radius: 50%;
      min-height: 50px;
      min-width: 50px;
    }
    div {
      margin: auto;
      flex: 1;
      > strong {
        font-family: 'Poppins';
        font-size: 1.4rem;

        color: #2f2f2f;
      }
    }

    svg {
      transition: 0.2s;
      margin: auto;
      color: #666360;
    }
  }

  .label {
    margin: 0px auto 20px auto;
    display: flex;
    position: relative;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    align-self: center;
  }

  /* Hide the browser's default checkbox */
  .label input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  /* Create a custom checkbox */
  .checkmark {
    position: absolute;
    top: 0px;
    left: 0;
    height: 25px;
    width: 25px;
    background-color: #eee;
  }

  /* When the checkbox is checked, add a blue background */
  .label input:checked ~ .checkmark {
    background-color: #2196f3;
  }

  /* Create the checkmark/indicator (hidden when not checked) */
  .checkmark:after {
    content: '';
    position: absolute;
    display: none;
  }

  /* Show the checkmark when checked */
  .label input:checked ~ .checkmark:after {
    display: block;
  }

  /* Style the checkmark/indicator */
  .label .checkmark:after {
    left: 9px;
    top: 5px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
  @media (max-width: 540px) {
    .div {
      width: 95%;
      display: grid;
      grid-template-columns: 0px 1fr 1fr 1fr 0px;
      text-align: center;
      cursor: default;
      div {
        > strong {
          font-size: 1.1rem;
        }
      }
    }
    a {
      grid-template-columns: auto 1fr 1fr 1fr 50px;
      img {
        width: 0px;
        height: 0px;
        min-height: 0px;
        min-width: 0px;
      }
      div {
        > strong {
          font-size: 1rem;
        }
      }
      svg {
        width: 50px;
      }
    }
  }
`
export const FormsContainer = styled.div`
  + button {
    margin: 0px auto;
  }
`
export const Dica = styled(Tooltip)`
  display: block;
`
