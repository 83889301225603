import React from 'react'

import Whats from '../../images/whatsapp.svg'
import Insta from '../../images/instagram.svg'
import LinkedIn from '../../images/linkedin.svg'
import Email from '../../images/email.svg'

import { Container } from './styles'

const Footer: React.FC = () => {
  return (
    <Container id="footer">
      <h1>Me sigam nas redes:</h1>
      <h2>rayanecezario@gmail.com</h2>
      <div className="iconArea" style={{ marginBottom: '30px' }}>
        <a
          href="https://api.whatsapp.com/send?phone=55329166-9191"
          target="_blank"
          rel="noreferrer"
        >
          <img src={Whats} id="whatsIcon" alt="Whatsapp Logo" />
        </a>

        <a
          href="https://instagram.com/arq.rayane?igshid=14vr7kh5ufm1m"
          target="_blank"
          rel="noreferrer"
        >
          <img src={Insta} id="instaIcon" alt="Instagram Logo" />
        </a>

        <a
          href="https://www.linkedin.com/in/rayane-lima-cez%C3%A1rio-4bbab910b/"
          target="_blank"
          rel="noreferrer"
        >
          <img src={LinkedIn} id="lkdIcon" alt="LinkedIn Logo" />
        </a>

        <a
          href="https://mail.google.com/mail/u/0/#inbox?compose=CllgCJTLqDdLVGvpKcbntrTTZxQGCQhGSpZnwwcSWBFRJWhHlqCPCRjjWTDLjcJSpQNHSmVNGxV"
          target="_blank"
          rel="noreferrer"
        >
          <img src={Email} id="lkdIcon" alt="Email Logo" />
        </a>
      </div>
      <p>Direitos reservados à &copy;FoldStack</p>
    </Container>
  )
}

export default Footer
