/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/ban-types */
import React from 'react'
import { FiX } from 'react-icons/fi'

import { Container, Content } from './styles'

interface Close {
  onClose(): void
  id: string
  title?: string
  subtitle?: string
  Tip?: object
}
const Modal: React.FC<Close> = ({
  id,
  title,
  Tip,
  subtitle,
  onClose,
  children,
}) => {
  const handleOutsideClick = (event: any) => {
    if (event.target.id === id) {
      onClose()
    }
  }
  return (
    <Container id={id} onClick={handleOutsideClick}>
      <Content
        isNavbar={id === 'navbar'}
        isAddImg={id === 'addImg'}
        isDeletar={id === 'deletar'}
      >
        <div>
          <div />
          <div>
            <h1>{title}</h1>
            <div style={{ display: 'flex' }}>
              {subtitle && <strong>{subtitle}</strong>}
              {Tip}
            </div>
          </div>
          <button type="button" className="exit" onClick={onClose}>
            <FiX size={40} />
          </button>
        </div>
        {children}
      </Content>
    </Container>
  )
}

export default Modal
