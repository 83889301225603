/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React from 'react'

import './styles/global.css'
import { BrowserRouter } from 'react-router-dom'
import Routes from './routes/index'

function App() {
  return (
    <BrowserRouter>
      <Routes />
    </BrowserRouter>
  )
}

export default App
