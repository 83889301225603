/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React from 'react'
import { Switch, Route } from 'react-router-dom'
import Router from './Route'
import Welcome from '../pages/Welcome'
import Login from '../pages/Login/login'
import Dashboard from '../pages/Dashboard/Dashboard'
import About from '../pages/About'
import Landing from '../pages/Landing'
import Portfolio from '../pages/Portfolio'
import Project from '../pages/Project'
import { AuthProvider } from '../hooks/AuthContext'
import { ProjectsProvider } from '../hooks/ProjectsContext'
import ProjectsEditor from '../pages/ProjectsEditor'
import { ToastProvider } from '../hooks/ToastContext'

function Routes() {
  return (
    <Switch>
      <ProjectsProvider>
        <Route path="/" exact component={Welcome} />
        <Route path="/about" component={About} />
        <Route path="/landing" component={Landing} />
        <Route path="/portfolio" component={Portfolio} />
        <Route path="/project/:id" component={Project} />
        <AuthProvider>
          <ToastProvider>
            <Router path="/login" component={Login} />
            <Router path="/dashboard" component={Dashboard} isPrivate />

            <Route path="/projects/:id" component={ProjectsEditor} />
          </ToastProvider>
        </AuthProvider>
      </ProjectsProvider>
    </Switch>
  )
}

export default Routes
