/* eslint-disable prettier/prettier */
import React from 'react'
import {
  RouteProps as ReactDOMRouteProps,
  Route as ReactDOMRoute,
  Redirect,
} from 'react-router-dom'
import { useAuth } from '../hooks/AuthContext'

interface RouteProps extends ReactDOMRouteProps {
  isPrivate?: boolean

  component: React.ComponentType
}

const Router: React.FC<RouteProps> = ({
  isPrivate = false,
  component: Component,
  ...rest
}) => {
  const { user } = useAuth()
  return (
    <ReactDOMRoute
      {...rest}
      render={() => {
        return isPrivate === !!user ? (
          <Component />
        ) : (
          <Redirect
            to={{
                pathname: isPrivate ? '/login'  : '/dashboard',
              }}
          />
          )
      }}
    />
  )
}

export default Router
